import Cookies from "js-cookie";
import constants from "../../resources/constants";

async function updateUser(data) {
    try {
        const response = await fetch(`${constants.API_URL_USER}/update-user`, {
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log(responseData)
            return responseData;
        } else {
            throw new Error("Update failed");
        }
    } catch (error) {
        throw error;
    }
}

const updateUserData = async (data,item,key) => {
    const clientToken = Cookies.get('clientToken');
    const updatedData = {
        [key]: item
    };
    const dataRaw = {
        token: clientToken,
        userId: data._id,
        updatedUser: updatedData
    }; 
    
    try {
        // await updateUser(dataRaw);
    } catch (error) {
        console.error('Error updating data:', error);
    } 
};
export default updateUserData;