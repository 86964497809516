import Cookies from "js-cookie";
import { Alert } from "react-native";
import constants from "../../resources/constants";
async function deleteUser(data) {
    const response = await fetch(`${constants.API_URL_USER}/delete-user`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData;
}

const deleteUserData = async (item) => {
    // DataRaw key değişebilir.
    const clientToken = Cookies.get('clientToken');
    const dataRaw = {
        token: clientToken,
        userId: item._id,
    };
    console.log(dataRaw);
    try {
       console.log("Kullanıcı silindi:",item._id)
        // await deleteUser(dataRaw);
    } catch (error) {
        console.log('error', error);
    }
};
export default deleteUserData;