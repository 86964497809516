import { View, Text,Modal, Dimensions } from 'react-native'
import React,{} from 'react'
import CloseButton from '../../components/buttons/closeButton';
import WrapperSubScreens from "../../components/wrapper/WrapperSubScreens"

 function AddModal({isModalVisible,setModalVisible,children}) {
  const height = Dimensions.get('window').height;
  return(
      <>
          <Modal
            visible={isModalVisible}
            animationType="slide"
            >
              <CloseButton  kind="addModal" onPress={() => setModalVisible(false)}/>
              <WrapperSubScreens>
                  {children}
              </WrapperSubScreens>
          </Modal>
      </>
  ) 
}

export default AddModal;