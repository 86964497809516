import React, { createContext } from 'react';

export const ThemeContext = createContext();

export const ThemeContextProvider = (props) => {
    const color = {
        blackColor:"#000",
        mainColor: "#99DBF5",
        darkBlueColor:"#279EFF",
        textColor:"#0d0d0d",
        greenbtnColor:"#96FF96",
        redbtnColor:"#FF5873",
        redswitchSoftColor:"#F2BED1",
        greenswitchSoftColor:"#9ED2BE",
        greenInputColor:"#73BBC9",
        purplebtnColor:"#500080",
        whiteColor:"#fff",
        lightPurpleColor:"#BD91E9",
        darkPurpleColor:"#9400FF",
        soDarkBlueColor:"#191D88",
        greyColor:"#EEEEF3",
        sunColor:"#f3b300",
        warningColor:"#f3cd61",
    }
    
    return (
        <ThemeContext.Provider value={color}>
            {props.children}
        </ThemeContext.Provider>
    );
};