import React,{useContext, useEffect} from "react";
import { TouchableOpacity, Text, StyleSheet, Dimensions } from "react-native";
import { ThemeContext } from "../../contexts/ThemeContext";
import globalStyles from "../../styles/GlobalStyles";
import { buttonStyler, buttonTextStyler } from "../../utilities/styleMethods/buttonStyleFunctions";
import { useRoute, useNavigation } from "@react-navigation/native";
const width = Dimensions.get('window').width;

const DrawerButton = ({ onPress,title,className,route }) => {
  const color = useContext(ThemeContext)
  let buttonStyle
  let buttonTextStyle;
  const homeButtonRaw = {
    ...globalStyles.shadowRadiusStyle,
    ...styles.homeButton
  }
  

  if(className==="drawer") {
    buttonStyle = buttonStyler(styles.drawerItem, color.mainColor, "auto" ,36)
    buttonTextStyle = buttonTextStyler(styles.buttonTextLeft,color.textColor,14)
  
  } // Home Drawer
  else {
    buttonStyle = buttonStyler(homeButtonRaw ,color.sunColor,width>500 ? 60 : 55, width>500 ? 55 : 45)
    buttonTextStyle = buttonTextStyler(styles.buttonText,color.whiteColor,width>500 ? 12 : 11)
  }
  
  function handlePress() {
    onPress();
  }
  const navigateRoute = useRoute()
  let routeButtonStyle;
  if(route === navigateRoute.name ) {
    routeButtonStyle = {
      ...buttonStyle,
      backgroundColor:color.warningColor
    } 
  } else {
    routeButtonStyle = {
      ...buttonStyle,
    } 
  }
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={handlePress}
      style={routeButtonStyle}
      >
        <Text style={buttonTextStyle}>{title}</Text>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  homeButton: {
    position:"absolute",
    left:10,
    borderRadius: width>500 ? 15 : 12,
    textAlign: "center",
    alignSelf:"center",
    justifyContent: "center",
    padding: 7,
  },
  exitButton: {
    borderRadius: 15,
    textAlign: "center",
    justifyContent: "center",
    padding: 7,
    fontSize: 16,
  },
  buttonText: {
    textAlign: "center",
  },
  buttonTextLeft: {
    textAlign: "left",
    fontWeight:"500",
    paddingHorizontal:7,
    paddingVertical:10,
  },
  drawerItem: {
    ...globalStyles(width).shadowDrawerStyle,
    marginHorizontal:10,
    marginBottom:6,
    paddingHorizontal:10
  },
});

export default DrawerButton;
