import React,{ useState,useContext } from "react";
import { Text, StyleSheet, View,Dimensions,Modal,TouchableOpacity,FlatList } from "react-native";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import globalStyles from "../../../../styles/GlobalStyles";
import { FlatlistButton } from "./flatlistButton";
import { handleFilterLowerCaseField } from "../../../../utilities/filterHelpers/handleFilterLowerCaseField";
import { makeUniqueData } from "../../../../utilities/filterHelpers/makeUniqueData";

const width = Dimensions.get('window').width;
const generalStyles = globalStyles(width)

const SetFlatlist = ({data, inputValue, buttonForm, setButtonForm, selectedItem, handleButtonChange, handleSelect,kind}) => {
  const color = useContext(ThemeContext)  
  let pickerStyle = {
    ...styles.picker,
    width:"100%",
    height:50,
  }

  function handleUserFilter (data) {
    return data.filter(item => inputValue ? handleFilterLowerCaseField(item.email, inputValue) : item)
  }

  function handleDeviceTypeFilter (data) {
    const uniqueData = makeUniqueData(data)
    return uniqueData.filter(item => inputValue ? handleFilterLowerCaseField(item.deviceType, inputValue) : item)
  }
  function handleFlatlistData(kind, data) {
    let flatlistData;
    if (kind === "device") {
      flatlistData = handleDeviceTypeFilter(data);
    } else if (kind === "user") {
      flatlistData = handleUserFilter(data);
    } else {
      console.log("Data türü belirlenemedi.");
      flatlistData = []; 
    }
    return flatlistData;
  }
  // console.log("gelenFlatlistData:",data)
  const renderItem = ({item,index}) => (
    buttonForm && selectedItem===item ? 
    (
      <View style={{flex:1,flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
          <FlatlistButton item={item} onPress={() => setButtonForm(false)} border="blue" kind={kind}/>
          <TouchableOpacity 
            style={[generalStyles.pickerChooseButton,generalStyles.shadowDrawerStyle,
              {backgroundColor:color.darkBlueColor,justifyContent:"center",alignItems:"center"}]} 
            onPress={() => handleSelect(item)}
            >
              <Text style={[generalStyles.pickerChooseText,{color:color.whiteColor}]}>Seç</Text>
          </TouchableOpacity>
      </View>
    ) : 
    (
      <FlatlistButton item={item} onPress={() => handleButtonChange(item)} kind={kind}/>
    )
  )
    return(
        <FlatList
            style={pickerStyle}
            data={handleFlatlistData(kind, data)}
            keyExtractor={(item,index) => `${item}${index}`}
            renderItem={renderItem}
            />
        )
}
const styles = StyleSheet.create({
  picker: {
    marginVertical:5,
    fontSize:width>=500 ? 20 :15,
    outlineStyle: "none",
    textAlign: "left",
    borderColor:"white"
  },
})
  export default SetFlatlist