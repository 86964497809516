import React,{ useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { Text, StyleSheet, View, TextInput,Dimensions } from "react-native";
import globalStyles from "../../styles/GlobalStyles";

const width = Dimensions.get('window').width;
const generalStyles = globalStyles(width)
// FilterComponent && DatepickerInputModal
const Input3 = ({style,value,onChange,keyboardType}) => {
  const color = useContext(ThemeContext)
  const handleClick = (value) => {
      onChange(value);
  };
  return (
    <View style={{flex:1}}>
        <TextInput
          value={value}
          multiline={style==="multiline" ? true : false}
          numberOfLines={style==="multiline" ? 2 : 1}
          onChangeText={handleClick}
          style={[styles.inputComponent,{backgroundColor:color.greenInputColor}]}
          inputMode={keyboardType}
          required={true}
          />
    </View>
  );
}

const styles = StyleSheet.create({
  inputComponent: {
    marginTop: 5,
    marginHorizontal:2,
    ...generalStyles.input,
    ...generalStyles.shadowRadiusStyle,
    borderRadius:10,
  },
  
})
export default Input3;