import React, { useContext, useState, useEffect } from "react";
import { View, Text,StyleSheet,Dimensions, FlatList } from "react-native";
import Input from "../../components/input/input"
import { ThemeContext } from "../../contexts/ThemeContext"
import ModalPicker from "../../components/pickers/labeledPicker/modalPicker/modalPicker";
import globalStyles from "../../styles/GlobalStyles";
import AddFilterButton from "../../components/buttons/addFilterButton";
import { useTranslation } from "react-i18next";

const width = Dimensions.get("window").width
const UserPermFields = ({informations,handleChange, data,createDataFunction,pickerData}) => {
    const color = useContext(ThemeContext)
    const generalStyles = globalStyles(width)
    // console.log(informations)
    const JSONInfo = JSON.stringify(informations)
    const {t} = useTranslation()
    const numColumns = (width) => {
        if (width >= 500) return 2; // Desktop, Tablet
        return 1;                   // Mobile
    };
    const initialPickerData = t("chooseUser")
    const [pickerValue,setPickerValue] = useState(initialPickerData)
   
    const renderItem = ({item,index}) => (
        <View key={index} style={generalStyles.fieldInput}>
        <Input
            backgroundColor="whiteColor"
            value={JSONInfo[item]}
            label={item.label}
            onChange={(value) => handleChange(item.database, value)}
        />
        </View>
      )
    useEffect(() => {
        return () => setPickerValue(initialPickerData)
    },[])
    return (
        <View style={{flex:1}}>
            <View style={generalStyles.fieldBorder}>
                <View style={styles.ScrollOutView}>
                    <View style={[generalStyles.addContainer,{flex:"none",flexWrap:"wrap"}]}>
                        <FlatList
                            data={data}
                            keyExtractor={(item,index) => `${item}-${index}`}
                            renderItem={renderItem}
                            numColumns={numColumns(width)}
                            />
                    </View>
                    <View style={{flexDirection:"row",marginBottom:20,margin:width>=500 ? 10 : 5,flexWrap:"wrap",paddingHorizontal:width>=500 ? 45 : 5}}>
                        <ModalPicker 
                            label={t("user")} 
                            kind="user"
                            data={pickerData} 
                            value={pickerValue} 
                            setValue ={setPickerValue} 
                            handleChange={handleChange}
                            />
                    </View>
                    <View style={[generalStyles.addContainerButton,{marginTop:40}]}>
                        <AddFilterButton style="add" title={t("addPermission")} onPress={() => createDataFunction(informations)} />
                    </View>
                    </View>
                </View>
        </View>
    );
}
const styles = StyleSheet.create({
    ScrollOutView:{ 
        flex: 1, 
        flexDirection: 'column', 
        justifyContent: "center"
        },
    })
export default UserPermFields