export const getAllKeys = (obj) => {
    let keys = [];
    for (let key in obj) {
      if (typeof obj[key] === "object" && Object.keys(obj[key]).length > 0) {
        // If the property is an object with nested data, recursively call the function
        keys = keys.concat(getAllKeys(obj[key]));
      } else {
        // If the property is not an object, add the value to the array
        keys.push(key);
      }
    }
    return keys;
}