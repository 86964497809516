import React, { useContext, useState, useEffect } from 'react';
import { View, Text,Dimensions } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Ionicons } from '@expo/vector-icons'; 
import { ThemeContext } from '../../contexts/ThemeContext';
import {UserPermissionScreen} from '../userScreens/userPermissions/UserPermissionScreen'
import UserSettingsScreen from '../userScreens/UserSettingsScreen';
import globalStyles from '../../styles/GlobalStyles';
import { useNavigation } from '@react-navigation/native';
import BackTabButton from '../../components/buttons/backTabButton';
import DrawerContent from '../../components/drawer/drawer';
import { GlobalStateContext } from '../../contexts/GlobalStatesContext';

const width = Dimensions.get('window').width;
const Tab = createMaterialTopTabNavigator()

const SingleUserTopTabScreen = () => {

    const navigation = useNavigation()
    const color = useContext(ThemeContext)
    const ScreenOptions = ({ route}) => ({
        
        tabBarIcon: ({ focused }) => {
          let iconName;
          if (route.name === 'user-permissions') {
            iconName = 'home';
          } else if (route.name === 'user-settings') {
            iconName = 'settings';
          }
          return (
            <View style={{ flex: 1,  zIndex:0,justifyContent: 'center', alignItems: 'center' }}>
              <Ionicons
                name={iconName}
                size={30}
                color={focused ? color.warningColor : color.whiteColor}
              />
            </View>
          );
        },
        tabBarShowLabel: false,
      });
    const TabBarOptions = {
        labelStyle: { fontSize: 14 },
        activeTabBarItemStyle: {
        fontSize: 18,
        fontWeight: 'bold',
        },
        style:[ globalStyles(width).tabStyle, 
        { marginLeft:width>=500 ? 0 : 20, backgroundColor: color.greenInputColor}
        ],
        indicatorStyle: {
          backgroundColor: color.warningColor,
          height:4,
        },
        tabBarShowLabel: false,
    }

    return (
      <View style={{flex:1,flexDirection:"row"}}>
            {width>=500 ?
              <View style={{flex:"none"}}>
                  <BackTabButton onPress={() => navigation.navigate("users")} title="Geri" />
                  <DrawerContent
                    kind="tab"
                    />
              </View>   :
              <>
                  <BackTabButton onPress={() => navigation.navigate("users")} title="Geri" />
                  <DrawerContent
                    kind="tab"
                    />
              </>
            }
            <Tab.Navigator
                swipeEnabled={false}
                screenOptions={({ route, }) => ({
                    ...ScreenOptions({ route, }),
                    
                })}
                tabBarOptions = {TabBarOptions}
                >
                <Tab.Screen 
                    name="user-permissions" 
                    component={(props) => <UserPermissionScreen {...props} />} 
                    />
                <Tab.Screen 
                    name="user-settings" 
                    component={UserSettingsScreen}
                    />
            </Tab.Navigator>
      </View>  
    )
}


export default SingleUserTopTabScreen;