import React, {createContext, useState, useEffect } from "react";

export const GeneralContext = createContext();
export const ContextProvider = (props) => {
    const [data, setData] = useState({
        query1: "",
        query2: "",
        query3: "",
        query4: "",
        query5: "",
        query6: "",
        query7: "",
        query8: "",
        hidden1:true,
        hidden2:true,
        hidden3:true,
        hidden4:true,
        currentData: [],
        filteredData: [],
        pagedData: [],
        currentPage: 1,
        itemsPerPage: 10,
        startIndex: 0,
        endIndex: 10,
        totalPages: [],
        pageNumbers:[],
      })
      
      function handlePagination() {
        const startIndex = (data.currentPage - 1) * data.itemsPerPage;
        const endIndex = startIndex + data.itemsPerPage;
        // When data filtered, also pagedData will change 
        const pagedData = data.filteredData.slice(startIndex, endIndex);
        const totalPages = Math.ceil(data.filteredData.length / data.itemsPerPage);
        const pageNumbers = [...Array(totalPages).keys()].map((num) => num + 1);
        setData((prevData) => ({
          ...prevData,
          pagedData:pagedData,
          startIndex,
          endIndex,
          totalPages,
          pageNumbers,
        }));
      }
      useEffect(() => {
        if(data.filteredData) {
          handlePagination()
        }
      }, [data.currentPage, data.itemsPerPage, data.filteredData]);

        const contextValue = { data, setData };

      return (
        <GeneralContext.Provider value={contextValue} >
          {props.children}
        </GeneralContext.Provider>
      );
    };