import constants from "../../resources/constants";
import Cookies from "js-cookie";
async function getReadingData(data) {
    try {
      const response = await fetch(`${constants.API_URL_USER}/get-device-data`, {
        method: 'POST',
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error("Hata:", error.message);
      return false;
    }
  }
  
  export const getReadingDataFunction = async (deviceId,page) => {
    const clientToken = Cookies.get('clientToken');
 
    const dataRaw = {
      "deviceId": deviceId,
      "token": clientToken,
      "page":page,
      "pageSize":24,
    };
    try {
      const responseData = await getReadingData(dataRaw);
      // console.log("getReadingData:",responseData)
      return responseData.result.data
    } catch (error) {
        console.log('error', error);
    }
  }