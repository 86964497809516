import React, {useContext } from "react";
import { View,Dimensions, ScrollView } from 'react-native'
import { ThemeContext } from "../../contexts/ThemeContext";
import globalStyles from "../../styles/GlobalStyles";

const width = Dimensions.get('window').width;
const WrapperSubScreens = ({children}) =>{
    const color = useContext(ThemeContext)
  let modalHeight;
  let modalFlex;
  if(width>=1030) {
    modalHeight = 550
  } else if(width>=768) {
    modalHeight = 700
  } else {
    modalHeight = 500
  }

return(
  <View style={{flex:1}}>
      <View style={[globalStyles(width).modalContainer]}>
          <ScrollView style={[globalStyles(width).modalContent,
            {backgroundColor:color.whiteColor,flex:1,minHeight:modalHeight}]}
            >
              {children}
          </ScrollView>
      </View>
  </View>
  )
}

export default WrapperSubScreens;