// Table initial değerine getirme.
export const clearContext = async (setData) => {
  await setData((prevData) => ({
      ...prevData,
      query1: "",
      query2: "",
      query3: "",
      query4: "",
      query5: "",
      query6: "",
      query7: "",
      query8: "",
      hidden1:true,
      hidden2:true,
      hidden3:true,
      hidden4:true,
      currentData:[],
      filteredData:[],
      pagedData: [],
      currentPage: 1,
      itemsPerPage: 10,
      startIndex: 0,
      endIndex: 10,
  }))
  }; 