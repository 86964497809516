import { View } from 'react-native'
import React,{useState,useEffect } from 'react'
import EntityFields from "./entityFields";
import FullScreenLoading from '../../components/loading/FullScreenLoading'
import createEntityData from '../../API/Entity/add-entity';
export const EntityOperations = ({inputFields,setModalVisible,handleListTableData}) => {
    const [informations, setInformations] = useState("")
    const [isLoadingCreate,setIsLoadingCreate] = useState(false)
    // Get Input Data
    const initialInformations = Object.fromEntries(inputFields.map(item => [item,""]))
    const handleChange = (type, value) => {
      setInformations((prevValue) => {
      return {
      ...prevValue,
      [type]: value,
      };
      });
    }; 
    // Mount Starts
    useEffect(() => {
        setInformations(initialInformations)
    },[])
    let labels;
    // labels = ["Organizasyon Adları", "Organizasyon Email Adresleri", "Organizasyon Telefon No"]
    const combinedArray = inputFields.map((field,index) => ({database:field,label:labels ? labels[index] : field}))

    async function handleCreateTableData(informations) {
        try {
          await createEntityData(informations, setIsLoadingCreate)
          await handleListTableData();
        } catch (error) {
          console.error('Error handling table data:', error);
        } finally {
          setIsLoadingCreate(false)
          setModalVisible(false)
        }
    }
  
    return(
        <View style={{flex:1}}>
          { isLoadingCreate ? 
          ( <FullScreenLoading message="Veri yükleniyor, lütfen bekleyin..."/>) :
          (
            <EntityFields 
              informations={informations} 
              handleChange={handleChange} 
              data={combinedArray} 
              createDataFunction={handleCreateTableData} 
              />  
          )
        }
        </View>
    )
}