import { createStackNavigator } from '@react-navigation/stack';
import React,{ } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { ForgotPassword } from '../ForgotPassword';
import { Login } from '../Login';

const Stack = createStackNavigator();
const headerStyleOptions = {
    headerShown:false,
};

const AuthStack = () => {
    return (
        <Stack.Navigator screenOptions={headerStyleOptions}>
            <Stack.Screen name="login" component={Login}  options={{ title:"" }}/>
            <Stack.Screen name="ForgotPassword" component={ForgotPassword}  options={{ title:""}}/>
        </Stack.Navigator>
    );
};

const styles = StyleSheet.create({
    logoContainer: {
        width: 250,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        height: '100%',
        width: '100%',
    },
});

export default AuthStack;