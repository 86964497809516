import constants from "../../resources/constants";
const loginAPI = async (data) => {
    try {
        const response = await fetch(`${constants.API_URL_USER}/login`, {
            method: 'POST',
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        console.log(responseData);
        return responseData;
    } catch (error) {
        console.error('Error during loginAPI:', error);
        throw error;
    }
};

const login = async(email, password, informations, updateGlobalState, getAPIToken) => {
    if(email === informations.email && password === informations.password) {
            // Fetch API after update states
            let data;
            updateGlobalState('isLoading', true);
            data = await loginAPI({
                "email": email,"password": password
            })
            await getAPIToken(data.token)
    } else {
        alert('Kullanıcı adı veya parola hatalı');
    }
}
export default login;