import Cookies from "js-cookie";
import constants from "../../resources/constants";
async function updateEntity(data) {
    const response = await fetch(`${constants.API_URL_USER}/update-entity`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData;
}

const updateEntityData = async (data,item,key) => {
    const clientToken = Cookies.get('clientToken');
    const updatedEntityData = {
        [key]: item
    };
    const dataRaw = {
        token: clientToken,
        entityId: data._id,
        updatedEntity: updatedEntityData
    };

    try {
        await updateEntity(dataRaw);
    } catch (error) {
        console.error('Error updating data:', error);
    }
};
export default updateEntityData;