import React, { useContext, useEffect, useState } from 'react';
import { View, Text, Dimensions, TouchableOpacity  } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Ionicons } from '@expo/vector-icons'; 
import { ThemeContext } from '../../contexts/ThemeContext';
import {SingleDeviceScreen} from '../devicesScreens/SingleDeviceScreen'
import globalStyles from '../../styles/GlobalStyles';
import DeviceReadingDataScreen from '../devicesScreens/deviceReadingData/DeviceReadingDataScreen';
import { useNavigation } from '@react-navigation/native';
import DrawerContent from '../../components/drawer/drawer';
import BackTabButton from '../../components/buttons/backTabButton';
import { GlobalStateContext } from '../../contexts/GlobalStatesContext';

const width = Dimensions.get('window').width;
const Tab = createMaterialTopTabNavigator()
const SingleDeviceTopTabScreen = ({route}) => {
    const navigation = useNavigation()
    const [deviceData,setData] = useState({})
    useEffect(() => {
        if(route.params) {
            const { data } = route.params;
            setData(data)
        } else {
            navigation.navigate("devices")
        }
    },[route])
    
    const color = useContext(ThemeContext)
    const ScreenOptions = ({ route }) => ({
        tabBarIcon: ({ focused }) => {
        let iconName;
        if (route.name === 'single-device') {
            iconName = 'home';
        } else if (route.name === 'device-data') {
            iconName = 'reader';
        } 
        return (
            <View style={{height:100}}>
                <Ionicons name={iconName}size={30}
                color={focused ? color.warningColor : color.whiteColor}
                />
            </View>
        );
    },
    tabBarShowLabel: false  // Tabbar Label bottom screen icon.
    })
    const TabBarOptions = {
        labelStyle: { fontSize: 16 },
        activeTabBarItemStyle: {
          fontSize: 20,
          fontWeight: 'bold',
        },
        style:[ globalStyles(width).tabStyle, 
        { backgroundColor: color.greenInputColor} 
        ],
        indicatorStyle: {
          backgroundColor: color.warningColor,
          height:4,
        },
    }
    return (
        <View style={{flex:1,flexDirection:"row"}}>
               {width>=500 ?
              <View style={{flex:"none"}}>
                  <BackTabButton onPress={() => navigation.navigate("devices")} title="Geri" />
                  <DrawerContent
                    kind="tab"
                    />
              </View>   :
              <>
                  <DrawerContent
                    kind="tab"
                    />
              </>
            }
            <Tab.Navigator
                swipeEnabled={false}
                screenOptions={ScreenOptions}
                tabBarOptions={TabBarOptions}
                >
                <Tab.Screen 
                    name="single-device" 
                    component={(props) => <SingleDeviceScreen {...props} data={deviceData}/>} 
                    />
                <Tab.Screen 
                    name="device-data" 
                    component={(props) => <DeviceReadingDataScreen {...props} data={deviceData}/>} 
                    />
            </Tab.Navigator>
        </View>
    );
};
export default SingleDeviceTopTabScreen;