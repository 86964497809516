import React,{ useContext } from 'react';
import { View, Text, ActivityIndicator, StyleSheet,Dimensions } from 'react-native';
import { ThemeContext } from "../../contexts/ThemeContext";
import globalStyles from '../../styles/GlobalStyles';

const width = Dimensions.get('window').width;
const CustomLoading = ({ message, style }) => {

    const color = useContext(ThemeContext)
    let viewWidth,indicatorSize,messageFontSize;
    if(style) {
        viewWidth = style.viewWidth
        indicatorSize = style.indicatorSize
        messageFontSize = style.messageFontSize
    } else {
        viewWidth = "auto"
        indicatorSize = 40
        messageFontSize = 16
    }

    return (
        <View style={[styles.container, {width:viewWidth}]}>
            <ActivityIndicator 
                size={indicatorSize} 
                color={color.textColor}
                />
            <Text style={[styles.message,{fontSize:messageFontSize}]}>
                {message}
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding:10,
        borderRadius:20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    message: {
        marginTop: 10,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#000',
    },
});

export default CustomLoading;