import { TouchableOpacity } from "react-native"
export const IconButton = ({Icon,onPress}) => {
    function handlePress() {
        onPress()
    }
    return(
        <TouchableOpacity onPress={handlePress} 
            style={{padding:5,width:40,height:40,marginRight:2,alignItems:"center",marginVertical:5}} >
            {Icon}
        </TouchableOpacity>
    )
}