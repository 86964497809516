import React,{useContext} from "react";
import { TouchableOpacity, Text, StyleSheet, Dimensions } from "react-native";
import { ThemeContext } from "../../contexts/ThemeContext";
import globalStyles from "../../styles/GlobalStyles";
import { buttonStyler, buttonTextStyler } from "../../utilities/styleMethods/buttonStyleFunctions";
const width = Dimensions.get('window').width;
// Drawer'da genelScreenlerin ve Çıkış butonu,
const ExitButton = ({ onPress,title}) => {
  const color = useContext(ThemeContext)
  let buttonStyle
  let buttonTextStyle;
  const rawStyle = {
    ...globalStyles(width).shadowDrawerStyle,
    ...styles.exitButton
  }
    buttonStyle = buttonStyler(rawStyle,color.redbtnColor,width>500 ? 60 : 55,width>500 ? 50 : 45)
    buttonTextStyle = buttonTextStyler(styles.buttonText,color.whiteColor,width>500 ? 13 : 11)

  function handlePress() {
    onPress();
  }
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={handlePress}
      style={buttonStyle}
      >
        <Text style={buttonTextStyle}>{title}</Text>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  exitButton: {
    borderRadius: 15,
    textAlign: "center",
    justifyContent: "center",
    padding: 7,
    fontSize: 16,
  },
  buttonText: {
    textAlign: "center",
  },
});

export default ExitButton;