import { useState,useContext} from "react";
import { Text, StyleSheet, View,Dimensions,Modal,TouchableOpacity,FlatList } from "react-native";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import globalStyles from "../../../../styles/GlobalStyles";

const width = Dimensions.get('window').width;
export const FlatlistButton = ({item,onPress,border,kind}) => {
    const color = useContext(ThemeContext)  
    let borderWidth,borderColor
    if(border==="blue") {
      borderWidth = 1.2
      borderColor= color.darkBlueColor
    } else {
      borderWidth = 0.6
      borderColor= color.textColor
    }
    let text;
    if (kind === "device") {
      text = item.deviceType;
    } else if (kind === "user") {
      text = item.email;
    } else {
      console.log("Data türü belirlenemedi.");
      text = text
    }
    return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={{flex:1,marginLeft:0,marginRight:15,marginVertical:3, borderWidth:borderWidth,borderColor:borderColor,borderRadius:12}}
      onPress={() => onPress(item)}
      >
        <Text style={[globalStyles(width).input,{
          fontSize: width>=500 ? 18 :16,
          color:color.textColor}]}>
          {text}
        </Text>
    </TouchableOpacity>)
  }