import React, { useState, createContext } from 'react';

// Create a new context named "apiLevel"
export const ApiLevelContext = createContext();

// Create a function that provides the context to the app
export const ApiLevelProvider = ({ children }) => {
    // Set the initial state of the context
    const [apiUrl, setUrl] = useState('https://userserver.ocloud.pro');
    // Define different API requests and related data storage
    const apiRequests = {
        // permissions
        getRoutes: async () => {
            const response = await fetch(`${apiUrl}`);
            const data = await response.json();
            return data;
        },
        login: async (data) => {
            const response = await fetch(`${apiUrl}/login`, {
                method: 'POST',
                body: JSON.stringify(data),
            });
            const responseData = await response.json();
            console.log(responseData);
            return responseData;

        },
        logout: async (data) => {
            const response = await fetch(`${apiUrl}/logout`, {
                method: 'POST',
                mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).catch(err => { console.log(err) }).then(response => { console.log(response) });
            console.log(response);
            const responseData = response;
            console.log(responseData);
            return responseData;
        },
        tokenCheck: async (data) => {
            const response = await fetch(`${apiUrl}/token-check`, {
                method: 'POST',
                body: JSON.stringify(data),
            });
            const responseData = await response.json();
            console.log(responseData);
            return responseData;
        }
        // permissions: async () => {
        //     const response = await fetch(`${apiUrl}/permissions`, {
        //         method: 'GET',
        //     });
        //     const responseData = await response.json();
        //     return responseData;
        // },
    };

    // Return the context provider with the apiLevel state and apiRequests functions
    return (
        <ApiLevelContext.Provider value={{ apiRequests }}>
            {children}
        </ApiLevelContext.Provider>
    );
};
