import React,{ useState,useContext } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import {linking} from "./linksURL"
import { createStackNavigator } from '@react-navigation/stack';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ThemeContext } from '../../contexts/ThemeContext';
import { useNavigation } from '@react-navigation/native';
import BackTabButton from '../../components/buttons/backTabButton';
const width = Dimensions.get('window').width;
const Stack = createNativeStackNavigator();
const PermissionsStack = () => {
  const navigation = useNavigation()
    const color = useContext(ThemeContext)
    const screenOptions = {
      backgroundColor:color.mainColor,
      headerShown:false,
    };

    const screens = linking.config.screens;
    return (
        <Stack.Navigator 
          initialRouteName="devices"  
          screenOptions={screenOptions}>
          {Object.keys(screens).map((screenName) => {
            const { route, component} = screens[screenName];
            return (
              <Stack.Screen
                key={route} 
                name={route}
                component={component}
                  
              />
            );
          })}
        </Stack.Navigator>
    );
};


export default PermissionsStack;