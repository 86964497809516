import ModalDataCell from "../../../components/cellComponents/modalDataCell";
import TextCell from "../../../components/cellComponents/textCell"
import {inputDataOnly} from "../../../utilities/formatMethods/insideFormats/inputDataOnly"
import { objectTransformer } from "../../../utilities/formatMethods/insideFormats/objectTransformer";

async function formatReadingData(inputData) {
    // Making data format
    const data = await inputData
    const outputData =  await inputDataOnly(data)
    // InputData component configurations
    inputData.forEach((item, index) => {
      for (let key in outputData.data) {
        let transformedItem = objectTransformer(item[key])
        if(key === "readout" ) {
          //  console.log(item[key])
            const previousCells = outputData.data[key].cells;
            const modalCell = (
              <ModalDataCell 
                data={item} 
                item={transformedItem} 
                />
            ) 
            outputData.data[key].cells = [...previousCells, modalCell];
        } 
        else {
            const previousCells = outputData.data[key].cells;
            const textCell = (
              <TextCell 
                data={item} 
                item={transformedItem} 
                keyName={key} 
                />
            ) 
            outputData.data[key].cells = [...previousCells, textCell];
        }
      }
    });
  
    return outputData;
  }
  export default formatReadingData;