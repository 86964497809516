export function handleIntCondition(key, value,handleChange) {
    if(value){
        if (/^\d+$/.test(value)) {
            let parsedValue = parseInt(value);
            handleChange(key, parsedValue);
        } else {
            console.log("Value is not a valid number");
        }
    } 
    else {
        handleChange(key, value)
    } 
}