import React,{useContext} from "react";
import { TouchableOpacity, Text, StyleSheet,View,Dimensions } from "react-native";
import {Ionicons} from '@expo/vector-icons'
import { ThemeContext } from "../../contexts/ThemeContext";
import globalStyles from "../../styles/GlobalStyles";
// Close Buttons 

const width = Dimensions.get('window').width;
const CloseButton = ({ onPress,kind }) => {
  const color = useContext(ThemeContext)
  function handlePress() {
    onPress();
  }
  let buttonStyle;
  if(kind==="addModal") {
    buttonStyle = {
      position:"absolute",
      zIndex:1,
      right:50,
      top:50,
      justifyContent:"flex-start",
      alignItems: "center",
    } 
  } else if(kind==="datePicker") {
    buttonStyle = {
      marginLeft:30,
      marginTop:-10,
      justifyContent:"flex-start",
      alignItems: "center",
    } 
  } else if(kind==="setUser") {
    buttonStyle = {
     ...globalStyles(width).endButton
    } 
  } 
  else {
    buttonStyle = {
      width:"auto",
      marginVertical:11,
      justifyContent:"center",
      alignItems: "center",
    }
  }
   
  return (
              <TouchableOpacity onPress={handlePress} style={buttonStyle}>
                  <View style={{ flexDirection: 'row', alignItems: 'center',justifyContent:"center" }}>
                    <Ionicons name='md-close-circle' size={40} color={color.redbtnColor}/>
                  </View>
              </TouchableOpacity>
       )
};


export default CloseButton;
