import Cookies from "js-cookie";
import constants from "../../resources/constants";
async function DynamicModel(data) {
    
    const response = await fetch(`${constants.API_URL_USER}/dynamic-models`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData;
}
const createUserDynamicModel  = async () => {
    const clientToken = Cookies.get('clientToken');
    const tokenRaw = {
        "token": clientToken,
        "modelName":"BaseUser",
    };
    try {
        const data = await DynamicModel(tokenRaw);
        return  Object.keys(data.result.model)
    } catch (error) {
        console.log('error', error);
    }
}

export default createUserDynamicModel;