import Cookies from "js-cookie";
import constants from "../../../resources/constants";
async function listUserPermissions(data) {
    const response = await fetch(`${constants.API_URL_USER}/get-user-permissions`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData;
}
const listUserPermissionsData  = async () => {
    const clientToken = Cookies.get('clientToken');
    const tokenRaw = {
        "token": clientToken
    };
    try {
        // console.log("Before list call")
        const data = await listUserPermissions(tokenRaw);
        // console.log(data);
        const tableData = data.result
        console.log(tableData)
        return tableData
    } catch (error) {
        console.log('error', error);
    }
    }
    export default listUserPermissionsData;
    