import { View, StyleSheet, Text,Dimensions } from 'react-native';
import React from "react";
import Input3 from '../input/input3'
import DatepickerInputModal from "../modals/DatepickerInputModal"
import ResetButton from '../buttons/resetButton';
import GlobalStyles from '../../styles/GlobalStyles';

const width = Dimensions.get('window').width;
const generalStyles = GlobalStyles(width)
const FilterComponent = ({ config, clearFilter,handleInputChange, data, setData }) => {
  const getInputField = (field, index) => (
    <View key={index} style={{ flex: 1}}>
        <Text style={generalStyles.inputLabel}>
          {field.label}
        </Text>
        <Input3
          value={data[field.name]} 
          onChange={(value) => 
          handleInputChange(field.name, value.toLowerCase(),setData)} 
          />
    </View>
  );
  return (
    <View style={{flex:width<500 ? 1 : "none"}}>
        <ResetButton title="reset" onPress={clearFilter} style="reset" />
        <View style={{ flexDirection: "row", marginTop: 8, alignItems: "flex-end" }}>
            {config.filter((field) => field.type==="text").map((field,index) => getInputField(field, index))}
            {config.filter((field) => field.type === 'date').map((field, index) => (
            <View key={`date_${index}`} >
                <View style={{alignItems:"flex-start",height:"auto"}}>
                    <Text style={[generalStyles.inputLabel,{height:"auto"}]}>{field.label}</Text>
                </View>
                <DatepickerInputModal key={`datepicker_${index}`} clearFilter={clearFilter}/>  
            </View>
          ))}
        </View>
    </View>
  );
};

export default FilterComponent;