export const getAllValues = (obj) => {
    let values = [];
    for (let key in obj) {
      if (typeof obj[key] === "object" && Object.keys(obj[key]).length > 0) {
        // If the property is an object with nested data, recursively call the function
        values = values.concat(getAllValues(obj[key]));
      } else if(typeof obj[key] === "object" ) {
        values.push("{}")
      } else if(typeof obj[key] === "string") {
            values.push(obj[key]) 
      }
        else  {
            values.push(JSON.stringify(obj[key]))
          }
      }
    // console.log(values)
    return values;
}