import Cookies from "js-cookie";
import constants from "../../resources/constants";

const logoutAPI = async (data) => {
  try {
      const response = await fetch(`${constants.API_URL_USER}/logout`, {
          method: 'POST',
          body: JSON.stringify(data),
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log(responseData);
      return responseData;
  } catch (error) {
      console.error('Error during logoutAPI:', error);
      throw error;
  }
};


const logout = async(removeAPIToken) => {
    const clientToken = Cookies.get('clientToken');
    const tokenRaw = {
      "token": clientToken
    };
    try {
      const data = await logoutAPI(tokenRaw)
      console.log(data)
      await removeAPIToken()
      }   
  catch (error) {
      console.log('error', error);
    }
  }
export default logout;