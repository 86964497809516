import React, { useState, useEffect, createContext } from 'react';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';
export const GlobalStateContext = createContext();
export const GlobalFunctionsContext = createContext();
import constants from '../resources/constants';

const toBase64Url = (str) => {
    const base64 = Buffer.from(str).toString('base64');
    return base64
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
};

const fromBase64Url = (encoded) => {
    encoded = encoded.replace(/-/g, '+').replace(/_/g, '/');
    while (encoded.length % 4) {
        encoded += '=';
    }
    return Buffer.from(encoded, 'base64').toString();
};

export const GlobalStateContextProvider = ({ children }) => {
    const [globalStates, setGlobalStates] = useState({
        authToken: false,
        isLoggedIn: false,
        isLoading: true,
        loadingMessage: 'Yükleniyor...',
        isDrawerSmall: false,
    });
    const [globalFunctions, setGlobalFunctions] = useState({
        getAPIToken,
        removeAPIToken,
    });

    const updateGlobalState = (key, value) => {
        setGlobalStates(prevState => ({ ...prevState, [key]: value }));
    }
    async function getAPIToken(newClientToken) {
        try {
            Cookies.set('clientToken', newClientToken);
            await putData(newClientToken, globalStates);
            updateGlobalState('isLoggedIn', true);
            updateGlobalState('isLoading', false);
        } catch (error) {
            console.error(error);
        }
    };
    async function removeAPIToken() {
        Cookies.remove('clientToken');
        updateGlobalState('isLoggedIn', false);
    };

    const apiUrl = constants.GLOBAL_STATES_URL;

    // Bu fonksiyon genellikle bir sunucu tarafı API ile etkileşimde 
    // bulunur ve belirli bir anahtar altında veriyi depolar. 
    // Veriyi Base64 kodlayarak, metin tabanlı verilerin daha 
    // güvenli bir şekilde iletilmesini sağlar.
    const putData = async (key, value) => {
        try {
            const base64Value = toBase64Url(JSON.stringify(value));
            const response = await fetch(`${apiUrl}/put`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "key": key, "value": base64Value })
            });
            console.log('body', { key: key, value: JSON.stringify(value) });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    };

    const getData = async (key) => {
        try {
            const response = await fetch(`${apiUrl}/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "key": key })
            });
            const data = await response.json();
            updateGlobalState('isLoading', false);
            return JSON.parse(fromBase64Url(data.data));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const clientToken = Cookies.get('clientToken');
        if (clientToken) {
            getData(clientToken).then((data) => {
                if (data) {
                    setGlobalStates(data);
                    console.table(data);
                } else {
                    Cookies.remove('clientToken');
                }
            });
        } else {
            updateGlobalState('isLoading', false);
        }
    }, []);

    useEffect(() => {
        if (globalStates.isLoggedIn) {
            const clientToken = Cookies.get('clientToken');
            putData(clientToken, globalStates);
        }
    }, [globalStates]);

    return (
        <GlobalStateContext.Provider value={{ ...globalStates, setGlobalStates, updateGlobalState }}>
            <GlobalFunctionsContext.Provider value={{ ...globalFunctions, setGlobalFunctions }}>

                {children}
            </GlobalFunctionsContext.Provider>
        </GlobalStateContext.Provider>
    );
};


//EXAMPLE OF USAGE
// import { useContext } from 'react';
// import { GlobalStateContext } from './GlobalStateContext';

// function MyComponent() {
//   const { authToken, setGlobalStates,updateGlobalState } = useContext(GlobalStateContext);

//   function handleAuthTokenChange(newToken) {
//     setGlobalStates(prevState => ({ ...prevState, authToken: newToken }));
//   }

//  updateGlobalState("authToken", token);

//   // rest of the component code
// }



/*
Koşul 1)
Kullanıcı ocloud.energy girdi 
    const clientToken = Cookies.get('clientToken');
    if (clientToken) {
                const data = await tokenCheck(clientToken);
                if (data) {
                    setGlobalStates(data);
                }


Koşul 2) 
Kullanıcı clientToken yoksa login ekranından giriş yapar.
        const newClientToken = uuidv4();  
        Cookies.set('clientToken', newClientToken);
        const data = await putData(newClientToken, globalStates);
        updateGlobalState("isLoggedIn", true);

Koşul 3)
Kullanıcı çıkış tuşuna bastığında:
        Cookies.delete('clientToken');
        Cookies.set('clientLoggedIn');
        updateGlobalState("isLoggedIn", false);


Koşul 4)
Kullanıcı sistemde her GeneralContexti güncellediğinde,
putData(clientToken,GeneralContext) fonksiyonu çalıştırılır.

*/