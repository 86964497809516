import EditableCell from "../../../components/cellComponents/editableCell"
import TextCell from "../../../components/cellComponents/textCell"
import {inputDataWithButton} from "../../../utilities/formatMethods/insideFormats/inputDataWithButton"
import DeleteButton from "../../../components/buttons/deleteButton"
import { objectTransformer } from "../../../utilities/formatMethods/insideFormats/objectTransformer";

async function formatEntityLevel(inputData,updateFunction,deleteFunction) {
    // Initialize the output object
    const data = await inputData
    const outputData =  await inputDataWithButton(data)
    
    // Data by using headings and keys
   
    inputData.forEach((item, index) => {
      for (let key in outputData.data) {
        let transformedItem = objectTransformer(item[key])
        if(key==="_id")  {
          const previousCells = outputData.data[key].cells;
          const textCell = <TextCell item={transformedItem}/>
          outputData.data[key].cells = [...previousCells, textCell];
        } else if(key==="Sil") {
          let previousButtonCells = outputData.data["Sil"]["cells"];
          const closeButton = (
            <DeleteButton 
              item={item}
              rowIndex={index}
              onPress={() => deleteFunction(item)} 
              />
          )
          outputData.data["Sil"]["cells"] = [...previousButtonCells,closeButton];
        } else {
          const previousCells = outputData.data[key].cells;
          const editableCell = (
            <EditableCell 
              data={item} 
              item={transformedItem} 
              keyName={key} 
              updateFunction={updateFunction} 
              />
          ) 
          outputData.data[key].cells = [...previousCells, editableCell];
          
        }
      }
    });

    return outputData;
  }
  export default formatEntityLevel;