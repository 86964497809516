import React,{useContext} from "react";
import { StyleSheet, TouchableOpacity, Text, Dimensions } from "react-native";
import { ThemeContext } from "../../contexts/ThemeContext";
import globalStyles from "../../styles/GlobalStyles";

const width = Dimensions.get('window').width;
const LoginButton = ({ onPress, title }) => {
  const color = useContext(ThemeContext)
  let appButtonContainerStyle = {
    ...globalStyles(width).shadowRadiusStyle,
    ...styles.appButtonContainer,
    backgroundColor:color.greenbtnColor
  }
  let appButtonTextStyle = {
    ...styles.appButtonText,
    color:color.whiteColor
  }
  return (
  <TouchableOpacity onPress={onPress} style={appButtonContainerStyle}>
    <Text style={appButtonTextStyle}>{title}</Text>
  </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  // ...
  appButtonContainer: {
    elevation: 8,
    paddingVertical: 10,
    paddingHorizontal: 12
  },
  appButtonText: {
    fontSize: 18,
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase"
  }
});
export default LoginButton;