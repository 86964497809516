import { Text,View,ScrollView,Dimensions} from "react-native";
import globalStyles from "../../styles/GlobalStyles";
import { useState,useEffect } from "react";
import { cellTrimmer } from "./cellTrimmer";
import { useTranslation } from "react-i18next";
const HeaderCell = ({item,kind,colCount}) => {
      const width = Dimensions.get('window').width;
      const generalStyles = globalStyles(width)
      const [value,setValue] = useState(item)
      const {t} = useTranslation()
      const[textValue,setText] = useState("")
      const maxLength = 40
      useEffect(() => {
      cellTrimmer({maxLength,value,setText})
     },[value])
     const maxColCount = width>=1000 ? 6 : 4
      return(
      <View style={generalStyles.cellOutView}>
            <ScrollView horizontal contentContainerStyle={{width:colCount>=maxColCount ? (generalStyles.cellInputWidth.width+10) : (width-305)/colCount, 
                  height:90,backgroundColor:"#9ED2BE"}}
                  >
                  <View style={[generalStyles.cellView]}>
                        <Text 
                              style={[generalStyles.cellComponent,
                              {fontSize:width>=500 ? 14 : 12}]}
                              >
                              {textValue}
                        </Text>
                  </View>
            </ScrollView>
      </View>
      )
}
  export default HeaderCell;