import Cookies from "js-cookie";
import constants from "../../resources/constants";
async function deleteEntity(data) {
    const response = await fetch(`${constants.API_URL_USER}/delete-entity`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData;
}

const deleteEntityData = async (item) => {
    // DataRaw key değişebilir.
    const clientToken = Cookies.get('clientToken');
    const dataRaw = {
        token: clientToken,
        entityId: item._id,
    };

    try {
        await deleteEntity(dataRaw);
    } catch (error) {
        console.log('error', error);
    }
};
export default deleteEntityData;