import React,{useContext} from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, Text, StyleSheet,Dimensions } from "react-native";
import { ThemeContext } from "../../contexts/ThemeContext";
import globalStyles from "../../styles/GlobalStyles";
import { buttonStyler, buttonTextStyler } from "../../utilities/styleMethods/buttonStyleFunctions";
const width = Dimensions.get('window').width;
// Screend Add Buttons && Form Buttons && Filter Component Buttons
const AddFilterButton = ({ title, onPress,style }) => {
  const color = useContext(ThemeContext)
  function handlePress() {
    onPress();
  }
  let buttonWidth; 
  if(width>= 1000) {
    buttonWidth = 150;
  } else if(width >= 500) {
    buttonWidth = 120;
  } else {
    buttonWidth = 110;
  }
  const rawStyle = {
    ...styles.button,
    ...globalStyles(width).shadowDrawerStyle,
    borderRadius:width>=500 ? 30 : 20,
    padding:5,
  }
  const buttonStyle = buttonStyler(rawStyle,color.darkBlueColor,buttonWidth,40)
  const buttonTextStyle = buttonTextStyler(globalStyles(width).centeredMidText,color.whiteColor)
  let buttonExtra
    // Add && Filter Forms
  if(style==="add" || style==="filter") {
    buttonExtra = {
      marginLeft:5
    }
  } // Filter Comp && Screen Add Buttons
  else {
    buttonExtra = {
    }
  }
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={[buttonStyle, buttonExtra]}
      onPress={handlePress}
      >
        <Text style={buttonTextStyle}>
          {title}
        </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 10,
    textAlign: "center",
    alignItems:"center",
    justifyContent:"center",
    fontSize: 12,
    alignSelf:"flex-end",
    marginVertical:5,
  },
});

export default AddFilterButton;
