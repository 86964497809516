import {UserPermissionLevelScreen} from '../userScreens/userLevel/UserPermissionLevelScreen'; 
import {DeviceLevelScreen} from '../devicesScreens/deviceLevel/DeviceLevelScreen'
import {EntityLevelScreen} from '../entitiesScreens/enitityLevel/EntityLevelScreen'
import SingleUserTopTabScreen from '../TabScreens/SingleUserTopTabScreen';
import SingleDeviceTopTabScreen from '../TabScreens/SingleDeviceTopTabScreen';
import { DashboardScreen } from '../DashboardScreen';
import { AllPermissionScreen } from '../allPermissionScreens/AllPermissionScreen';
// Burada değişiklik yaparsan, dashboarddaki navigate de ayarla 
export const linking = {
    prefixes: ["http://localhost:19006/","OcloudNativeClient://"],
    config: {
        screens :{
            DashboardScreen: {
                route: "home",
                component: DashboardScreen,
                header: "home",
            },
            UserPermissionLevelScreen: {
                route: "users",
                component: UserPermissionLevelScreen,
                header: "users",
            },
            EntityLevelScreen: {
                route: "entities",
                component: EntityLevelScreen,
                header: "organisations",
            },
            DeviceLevelScreen: {
                route: "devices",
                component: DeviceLevelScreen,
                header: "devices",
            },
            // ModulesScreen: {
            //     route: "modules",
            //     component: ModulesScreen,
            //     header: "Modüller",
            // },
            // CronDBScreen: {
            //     route: "crondb",
            //     component: CronDBScreen,
            //     header: "CronDB",
            // },
            // ToolsScreen: {
            //     route: "tools",
            //     component: ToolsScreen,
            //     header: "Tools",
            // },
            SingleUserTopTabScreen: {
                route: "single-user-tab",
                component: SingleUserTopTabScreen,
                header: "Seçilen Kullanıcı Sayfası",
            },
            SingleDeviceTopTabScreen: {
                route: "single-device-tab",
                component: SingleDeviceTopTabScreen,
                header: "Seçilen Cihaz Sayfası",
            },
            // PermissionsLibraryScreen: {
            //     route: "permissionlibrary",
            //     component: PermissionsLibraryScreen,
            //     header: "Yetki Kütüphanesi",
            // },
            AllPermissionsScreen: {
                route: "all-user-permissions",
                component: AllPermissionScreen,
                header: "allPermissions",
            }
        }
    }
}


    

  
  