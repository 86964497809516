import Cookies from "js-cookie";
import constants from "../../../resources/constants";
async function createUserPermission(data) {
    const response = await fetch(`${constants.API_URL_USER}/add-user-permission`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    // console.log(responseData)
    return responseData;
}
const createUserPermissionData = async (informations, setIsLoadingCreate) => {
    console.log("çalıştı userperm")
    setIsLoadingCreate(true);
    const clientToken = Cookies.get('clientToken');
    const permissionData = {
        ...informations,
        createdAt: {
            $date: {
                $numberLong: "1722958200000"
            }
        },
        updatedAt: {
            $date: {
                $numberLong: "1722958200000"
            }
        }
    };
    console.log(permissionData);
    const dataRaw = {
        token: clientToken,
        newUserPermission: permissionData
    };
    console.log(dataRaw);
    
    try {
        // console.log("Before create call")
        await createUserPermission(dataRaw);
    } catch (error) {
        console.log('error', error);
    } 
};
export default createUserPermissionData;