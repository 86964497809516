import React, { useContext } from 'react';
import { NavigationContainer,DefaultTheme, useNavigation } from '@react-navigation/native';
import { Dimensions, Image, View, StyleSheet } from 'react-native';
import PermissionsStack from './screens/StackScreens/PermissionsStackScreen ';
import AuthStack from './screens/StackScreens/AuthStackScreen';
import { ApiLevelProvider } from './contexts/ApiLevelContext';
import { ThemeContextProvider } from './contexts/ThemeContext';
import { ContextProvider } from './contexts/context';
import { createStackNavigator } from '@react-navigation/stack';
import { GlobalStateContext, GlobalStateContextProvider } from './contexts/GlobalStatesContext';
import FullscreenLoading from './components/loading/FullScreenLoading';
import { ThemeContext } from './contexts/ThemeContext';
import LanguageButton from './components/buttons/languageButton';
import BackTabButton from './components/buttons/backTabButton';

const width = Dimensions.get('window').width;
const Stack = createStackNavigator();

function App() {
  const { isLoggedIn, isLoading, loadingMessage, updateGlobalState } = useContext(GlobalStateContext);
  const color = useContext(ThemeContext)
  const navTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: 'transparent',
    },
  };
  if (isLoading) {
    return (<FullscreenLoading message={loadingMessage} ></FullscreenLoading>);
  } else {
    return (
      <NavigationContainer
      linking={{enabled:true}}
      theme={navTheme}
      >
        <Stack.Navigator
          screenOptions={{
            headerShadowVisible: false,
            headerTitle:() => null,
            headerRight:() => <LanguageButton/>,
            headerShown:  true
          }}
          
        >
          {isLoggedIn == false ? <Stack.Screen name="auth" component={AuthStack} options={{headerStyle:{backgroundColor:"black"}}} /> : false}
          {isLoggedIn == true  ? <Stack.Screen name="user" component={PermissionsStack} /> : false }
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
}

function Def_App() {
  return (
    <ApiLevelProvider>
      <GlobalStateContextProvider>
        <ThemeContextProvider>
          <ContextProvider>
            <App></App>
          </ContextProvider>
        </ThemeContextProvider>
      </GlobalStateContextProvider >
    </ApiLevelProvider>
  );
}

export default Def_App;

