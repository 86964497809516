export const buttonStyler = (buttonRawStyle, backgroundColor, 
  width, height, padding) => {
    return {
      ...buttonRawStyle,
      backgroundColor:backgroundColor,
      width:width,
      height:height,
      padding:padding,
    }
  }
export const buttonTextStyler = (buttonTextRawStyle, color,fontSize) => {
    return {
      ...buttonTextRawStyle,
      color:color,
      fontSize:fontSize,
    }
  }