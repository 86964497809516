import { View, Text, Dimensions,StyleSheet } from 'react-native'
import React,{useState,useEffect, useContext,useCallback} from 'react'
import TableComponent from "../../../components/table/index";
import AddModal from '../../../components/modals/addModal';
import globalStyles from '../../../styles/GlobalStyles';
import JSONData from '../../../data/tableFormat.json';
import listEntityPermissionLevels from '../../../API/Entity/list-entities'
import updateEntityData from '../../../API/Entity/update-entity'
import deleteEntityData from '../../../API/Entity/delete-entity'
import { ThemeContext } from '../../../contexts/ThemeContext';
import FullScreenLoading from '../../../components/loading/FullScreenLoading';
import formatEntityLevel from './formatEntityLevel';
import { GeneralContext } from '../../../contexts/context';
import Wrapper from '../../../components/wrapper/wrapper';
import { EntityOperations } from '../../../forms/addEntity/entityOperations';
import { useFocusEffect } from '@react-navigation/native';
import { clearContext } from '../../../utilities/clearContext';
import createEntityDynamicModel from '../../../API/Entity/dynamic-model-entity';
import AddFilterButton from '../../../components/buttons/addFilterButton';
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../../../contexts/GlobalStatesContext';
export function EntityLevelScreen() {
  const [tableData,setTableData] = useState(JSONData)
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoadingTable,setIsLoadingTable] = useState(false)
  const [inputFields,setInputFields] = useState([])
  const{isDrawerSmall} = useContext(GlobalStateContext)
  const generalStyles = globalStyles(width)
  const {t} = useTranslation()
  const color = useContext(ThemeContext)
  const width = Dimensions.get('window').width;
  const { 
    data,
    setData
  } = useContext(GeneralContext)

  async function getInputFields () {
    try{
      const inputFields = await createEntityDynamicModel()
      console.log(inputFields)
      setInputFields(inputFields)
    } catch(e) {
      console.error
    }
  }
  async function handleListTableData() {
    try {
      const data = await listEntityPermissionLevels();
        setData((prev) => ({
          ...prev,
          currentData: data,
          filteredData:data,
        }));
        formatData(data)
        getInputFields()
    } catch (error) {
      console.error('Error handling table data:', error);
    } finally {
      setIsLoadingTable(false)
    }
  }

  async function handleDeleteTableData(item) {
    try {
      await deleteEntityData(item)
      await handleListTableData();
    } catch (error) {
      console.error('Error handling table data:', error);
    }
  }
  async function handleUpdateTableData(data,item,key) {
    try {
      await updateEntityData(data,item,key)
      await handleListTableData();
    } catch (error) {
      console.error('Error handling table data:', error);
    }
  }
    // Handle list ve filterOperationsda debounceSeachde çalıştırılır.
  async function formatData (data) {
    // console.log(data)  
    const formatData = await formatEntityLevel(
                          data,
                          handleUpdateTableData,
                          handleDeleteTableData
                          )
    // console.log(formatData)
    setTableData(formatData)
  }
  
  useFocusEffect(
    useCallback(() => {
      setIsLoadingTable(true);
      handleListTableData()
      return () => clearContext(setData)
    }, [])
  );
  return (
    <>{isLoadingTable ? (<FullScreenLoading message="Tablo Yükleniyor, lütfen bekleyin..."/>) :
        (
        <Wrapper 
          >
            <AddModal isModalVisible={isModalVisible} setModalVisible={setModalVisible}>
                <EntityOperations 
                  inputFields={inputFields} 
                  setModalVisible={setModalVisible} 
                  handleListTableData={handleListTableData}
                  />
            </AddModal>
            <View style={generalStyles.tableWrapper}>
                <View style={{flex:1}}>
                    <AddFilterButton style="add" title={t("addEntity")} onPress={() => setModalVisible(true)}/>
                    <View style={generalStyles.pageTableBorder}>
                        <TableComponent data={tableData} colCount={7} isDrawerSmall={isDrawerSmall}/> 
                    </View>
                </View>
            </View>
        </Wrapper>
        )
    }
    </>
  )
}