import React, { useContext } from "react";
import { View, Text,StyleSheet,Dimensions, ScrollView,FlatList } from "react-native";
import Input from "../../components/input/input"
import { ThemeContext } from "../../contexts/ThemeContext"
import AddFilterButton from "../../components/buttons/addFilterButton";
import globalStyles from "../../styles/GlobalStyles";
import { useTranslation } from "react-i18next";

const width = Dimensions.get("window").width
const EntityFields = ({informations,handleChange,data,createDataFunction}) => {
    const color = useContext(ThemeContext)
    const {t} = useTranslation()
    const JSONInfo = JSON.stringify(informations)
    const generalStyles = globalStyles(width)
    const numColumns = (width) => {
        if (width >= 500) return 2; // Desktop, Tablet
        return 1;                   // Mobile
    };
    
    const renderItem = ({item,index}) => (
        <View key={index} style={generalStyles.fieldInput}>
            <Input
                backgroundColor="whiteColor"
                value={JSONInfo[item]}
                label={item.label}
                onChange={(value) => handleChange(item.database, value)}
            />
        </View>
    )
    return (
        <View style={{flex:1}}>
            <View style={generalStyles.fieldBorder}>
                    <View style={{flex:1,width:width>=500 ? width-200 : "auto"}}>
                        <View style={generalStyles.addContainer}> 
                            <View style={generalStyles.fieldFlatlistView}>
                                 <FlatList
                                    data={data}
                                    keyExtractor={(item,index) => `${item}${index}`}
                                    renderItem={renderItem}
                                    numColumns={numColumns(width)}
                                    />
                            </View>
                        </View>
                        <View style={generalStyles.addContainerButton}>
                            <AddFilterButton style="add" title={t("addEntity")} onPress={() => createDataFunction(informations)}/>
                        </View>
                    </View>
            </View>
        </View>
    );
}
const styles = StyleSheet.create({
    ScrollOutView:{ 
        flex: 1, 
        flexDirection: 'row', 
        justifyContent: "center"
        },
    })
export default EntityFields