import { View, Text, Dimensions,StyleSheet } from 'react-native'
import React,{useState,useEffect, useContext,useCallback } from 'react'
import TableComponent from "../../../components/table/index";
import AddModal from '../../../components/modals/addModal';
import globalStyles from '../../../styles/GlobalStyles';
import JSONData from '../../../data/tableFormat.json';
import listDevicePermissionLevels from '../../../API/Device/list-devices'
import updateDeviceData from '../../../API/Device/update-device'
import deleteDeviceData from '../../../API/Device/delete-device'
import { ThemeContext } from '../../../contexts/ThemeContext';
import FullScreenLoading from '../../../components/loading/FullScreenLoading';
import formatDeviceLevel from './formatDeviceLevel';
import { GeneralContext } from '../../../contexts/context';
import Wrapper from '../../../components/wrapper/wrapper';
import { getInputsData } from '../../../utilities/getInputsData';
import { DeviceOperations } from '../../../forms/addDevice/deviceOperations';
import DeviceFilterOperations from '../../../forms/filterDevice/deviceOperations';
import { clearContext } from '../../../utilities/clearContext';
import { useFocusEffect } from '@react-navigation/native';
import createDeviceDynamicModel from '../../../API/Device/dynamic-model-device';
import AddFilterButton from '../../../components/buttons/addFilterButton';
import { useTranslation } from 'react-i18next';
export function DeviceLevelScreen() {
  const [tableData,setTableData] = useState(JSONData)
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoadingTable,setIsLoadingTable] = useState(false)
  const [inputFields,setInputFields] = useState([])
  const [isDrawerSmall, setContainerSize] = useState(false);
  const {t} = useTranslation()
  const changeContainerSize = () => {
    setContainerSize(!isDrawerSmall);
  };
  const color = useContext(ThemeContext)
  const width = Dimensions.get('window').width;
  const generalStyles = globalStyles(width)
  const { 
    data,
    setData
  } = useContext(GeneralContext)
  async function getInputFields () {
    try{
      const inputFields = await createDeviceDynamicModel()
      const limitedFields = inputFields.filter(inputField => inputField!=="updatedAt" && inputField!=="createdAt")
      setInputFields(limitedFields)
    } catch(e) {
      console.error
    }
  }
  async function handleListTableData() {
    // setData(filteredData...) kısmı - Filterda kullanılacak data, 
    // formatData kısmı - tableda formatlanacak data, 
    // getInputsData kısmı - de cihaz ekleme ve filtrelemede kullanılacak inputların datası
    try {
      const data = await listDevicePermissionLevels();
        setData((prev) => ({
          ...prev,
          currentData: data,
          filteredData:data,
        }));
        formatData(data)
        getInputFields()
    } catch (error) {
      console.error('Error handling table data:', error);
    } finally {
      setIsLoadingTable(false)
    }
  }
  async function handleDeleteTableData(item) {
    try {
      await deleteDeviceData(item)
      await handleListTableData();
    } catch (error) {
      console.error('Error handling table data:', error);
    }
  }
  async function handleUpdateTableData(data,item,key) {
    try {
      await updateDeviceData(data,item,key)
      await handleListTableData();
    } catch (error) {
      console.error('Error handling table data:', error);
    }
  }
    // Handle list ve filterOperationsda debounceSeachde çalıştırılır.
    // Burada sadece gelen Datanın formatlanması ve formattedData'nın table'a aktarılması gerçekleşir.
  async function formatData (data) {
    console.log(data)
    const formattedData = await 
      formatDeviceLevel(
        data,
        handleUpdateTableData,
        handleDeleteTableData,
        )                
    setTableData(formattedData)
  }
  useFocusEffect(
    useCallback(() => {
      setIsLoadingTable(true);
      handleListTableData()
      return () => clearContext(setData)
    }, [])
  );

  function openModal() {
    setModalVisible(true)
  }
  
  return (
    <>{isLoadingTable ?  (<FullScreenLoading message="Tablo Yükleniyor, lütfen bekleyin..."/>):
        (
        <Wrapper 
          isDrawerSmall={isDrawerSmall} 
          changeContainerSize={changeContainerSize}
          >
            <AddModal isModalVisible={isModalVisible} setModalVisible={setModalVisible}>
                <DeviceOperations 
                  inputFields={inputFields} 
                  setModalVisible={setModalVisible} 
                  handleListTableData={handleListTableData}
                  />
            </AddModal>
            <View style={generalStyles.tableWrapper}>
                <View style={{flex:1}}>
                    <AddFilterButton style="add" title={t("addDevice")} onPress={openModal}/>
                    <View style={generalStyles.pageTableBorder}>
                        <TableComponent data={tableData} colCount={8} isDrawerSmall={isDrawerSmall}/>
                    </View>
                </View>
            </View>
        </Wrapper>
        )
    }
    </>
  )
}
