import React, { useEffect, useState, useContext } from 'react';
import { View, Text, TouchableOpacity, Modal, FlatList,Dimensions,StyleSheet } from 'react-native';
import CloseButton from '../../buttons/closeButton';
import { useTranslation } from "react-i18next";
import { ThemeContext } from '../../../contexts/ThemeContext';
import { getYears } from "../../../utilities/datePickerMethods/getYears"
import { listMonthsOfYear } from '../../../utilities/datePickerMethods/listMonthsOfYear';
import { listDaysOfSelectedDates } from '../../../utilities/datePickerMethods/listDaysOfSelectedDates';
import { DatePickerComponent } from './datePickerComponent';
import globalStyles from '../../../styles/GlobalStyles';

const width = Dimensions.get('window').width;
const generalStyles = globalStyles(width)
const TimeAsDatePicker = ({ visible, onCancel,setLabel,clearFilter }) => {
  const {t} = useTranslation()
  const color = useContext(ThemeContext)
  const initialData = {
    years:[],
    months:[],
    days:[],
    startYear: "",
    currentYear: "",
    year:"2000",
    month: "1",
    day: "1",
    hour:"12",
    minute: "30",
    second:"30",
  }
  const [data,setData] = useState(initialData)
  const updateData = (key, value) => {
    setData(prevState => ({ ...prevState, [key]: value }));
  }
  // Get seconds, minutes, and hours
  const seconds = Array.from({ length: 60 }, (_, i) => i );
  const minutes = Array.from({ length: 60 }, (_, i) => i );
  const hours = Array.from({ length: 24 }, (_, i) => i);

  function closeModal() {
    setLabel(data.day+"/"+data.month+"/"+data.year+" - "+data.hour+":"+data.minute+":"+data.second)
    onCancel()
  }

  useEffect(() => {
    const days = listDaysOfSelectedDates(data.year, data.month)
    const day = data.day
    const lastDay = days.length
    updateData("days", days);
    if(days.length<data.day) {
      updateData("day",lastDay)
    } else {
      updateData("day",day)
    }
    
  },[data.year, data.month])

  useEffect(() => {
    const months = listMonthsOfYear()
    updateData("months", months)
    const years = getYears(updateData);
    updateData("years",years)
  },[visible])
  useEffect(() => {
    setData(initialData)
    },[clearFilter])

const datePickerData = [
  {label:"Gün",key:"day",data:data.days},
  {label:"Ay",kind:"month",key:"month",data:data.months},
  {label:"Yıl",key:"year",data:data.years},
  {label:"Saat",key:"hour",data:hours},
  {label:"Dakika",key:"minute",data:minutes},
  {label:"Saniye",key:"second",data:seconds},
  ]
  return (
    <Modal visible={visible} transparent={true} animationType="slide">
      <View style={[generalStyles.modalContainer,
        {justifyContent: "center",alignItems: "center"}]}
        >
        <View style={[generalStyles.modalContent,
          styles.modalContentExtra,
          {backgroundColor:color.whiteColor}
          ]}
          >
          {datePickerData.map((field,index) =>
            <DatePickerComponent
              label={field.label}
              kind={field.kind}
              key={index}
              pickerValue={data[field.key]}
              setPickerValue={(value) => updateData(field.key,value)}
              pickerStyle={styles.picker}
              data={field.data}
              />
          )}
            <CloseButton kind="datePicker" onPress={closeModal}/>
        </View>
      </View>
    
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContentExtra: {
    flex:"none",
    flexDirection:"row",
    width:width/2,
    height:width/4,
    justifyContent: "center",
    alignItems: "flex-start",
    padding:20,
    borderRadius:20,
  },
  picker: {
    marginTop:10,
    width:90,
    height:50,
    flexDirection:"row",
    fontSize:width>=1000 ? 18 :16,
    borderWidth: 1,
    borderRadius:10,
    padding:width>=1000 ? 7 : 5,
    outlineStyle: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    padding:7,
    paddingVertical:40,
    fontSize:20,
    fontWeight:"bold",
    borderBottomWidth:1,
    borderRightWidth:1,
    flex:1
    },
});

export default TimeAsDatePicker;