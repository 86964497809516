import React, {useContext } from "react";
import { ScrollView,View,Dimensions } from 'react-native'
import { ThemeContext } from "../../contexts/ThemeContext";
import DrawerContent from "../drawer/drawer";

const height = Dimensions.get('window').height;
const width = Dimensions.get('window').width;
function Wrapper ({ children,kind }) {
    const color = useContext(ThemeContext)
return(
  <>
  {width<500 ? 
   <View style={{flex:1,flexDirection:"row"}}>
       {kind!=="noDrawer" && 
        <DrawerContent 
          />
        }
        {<ScrollView contentContainerStyle={{marginLeft:kind==="noDrawer" ? 0 : 40, padding:6,
          backgroundColor:color.whiteColor,minHeight:height+100}}
          >
          {children}
        </ScrollView>}
  </View>  :
    <ScrollView contentContainerStyle={{padding:6,backgroundColor:color.whiteColor}}>
      <View style={{flexDirection:"row"}}>
          {kind!=="noDrawer" && 
          <DrawerContent 
            />
          }
          {children}
        </View> 
    </ScrollView>
  }
  </>
)
}
export default Wrapper;