import { View, Text, StyleSheet, Dimensions } from 'react-native'
import React,{ useState,useContext,useEffect,useCallback } from 'react'
import { ThemeContext } from '../../../contexts/ThemeContext';
import { getReadingDataFunction } from '../../../API/Device/get-reading-device-data';
import globalStyles from '../../../styles/GlobalStyles';
import FullScreenLoading from '../../../components/loading/FullScreenLoading';
import TableComponent from '../../../components/table';
import JSONData from '../../../data/tableFormat.json'
import ClockIndicator from '../../../components/clockIndicator/clockIndicator';
import formatReadingData from './formatReadingData';
import { GlobalStateContext } from '../../../contexts/GlobalStatesContext';
const width = Dimensions.get("window").width
const DeviceReadingDataScreen = ({data}) => {
  const color = useContext(ThemeContext)
  const [isLoadingTable,setIsLoadingTable] = useState(false)
  const [isLoadingPage,setIsLoadingPage] = useState(false)
  const{isDrawerSmall} = useContext(GlobalStateContext)
  const [page, setPage] = useState(1)
  const[tableData,setTableData] = useState(JSONData)
  const[pageData,setPageData] = useState([])
    // Sadece Handle listte çalıştırılır.
  async function formatData(data) {
    console.log("sum:",data);
    try{
      const formatData = await formatReadingData(data);
      console.log(formatData)
      setTableData(formatData);
    }catch(e){
      console.log(e.message)
    } finally {
      setIsLoadingPage(false);
      setIsLoadingTable(false)
    }
}
async function handleListTableData(page, data) {
  try {
    const readingData = await getReadingDataFunction(data._id, page);
    setPageData((prevData) => {
      if (Array.isArray(readingData)) {
        const updatedData = [...prevData, ...readingData];
        formatData(updatedData); // Call formatData here
        return updatedData;
      } else {
        // console.error("readingData is not an array or an iterable object.");
        return prevData;
      }
    });
  } catch (error) {
    console.error('Error handling table data:', error);
  }
}

  function handleLoadMore() {
    console.log("loadmore tetiklendi")
      setPage(page + 1);
  }


  useEffect(() => {
    console.log("xd",page,data)
      setIsLoadingPage(true)
      setIsLoadingTable(true)
      handleListTableData(page,data);
    return () => {
      setPageData([]);
      setPage(1)
    };
  },[])

  return (
    <>{isLoadingPage ?  
    (<FullScreenLoading message="Sayfa Yükleniyor, lütfen bekleyin..."/>):
    (   
    <View style={{flex:1,marginLeft:width>=500 ? 0 : 60}}>
        <ClockIndicator duration={60}/>
        <TableComponent 
          kind="Reading"
          data={tableData} 
          colCount={1} 
          isDrawerSmall={isDrawerSmall} 
          handleLoadMore={handleLoadMore}
          isLoadingTable={isLoadingTable}
          />
    </View>
   
    )
}
</>
  )
}
const styles = StyleSheet.create({
  container: {
    ...globalStyles(width).shadowRadiusStyle,
    marginTop:30,
    flexDirection:"row",
    padding:6,
    borderRadius:20, 
    alignSelf:"center",
    width:width>=500 ? width/2 : width
  }
})
export default DeviceReadingDataScreen;