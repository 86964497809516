import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, TextInput, Button,StyleSheet,Dimensions} from 'react-native';
import { ThemeContext } from '../../contexts/ThemeContext';
import globalStyles from '../../styles/GlobalStyles';
import CloseButton from '../buttons/closeButton';
import { RepeatableInputButton } from "./repetableInputButton"

const width = Dimensions.get('window').width;
const RepeatableInput = ({repetableFields, setRepetableFields}) => {
  const color = useContext(ThemeContext)
  const {t} = useTranslation()
  // HandleInputChange for all inputs
  const updateInputField = (index, key, value) => {
      const updatedFields = [...repetableFields];
      updatedFields[index] = { key, value };
      setRepetableFields(updatedFields);
  };

  const addInputField = () => {
    setRepetableFields([...repetableFields, { key: '', value: '' }]);
  };
  const removeInputField = () => {
    const newInputFields = [...repetableFields];
    newInputFields.splice(repetableFields.length-1, 1);
    setRepetableFields(newInputFields);
  };
 function removeSelectedInput (index) {
  const newInputFields = [...repetableFields];
  newInputFields.splice(index, 1);
  setRepetableFields(newInputFields);
 }
  return (
    <View style={{flex:1,justifyContent:"flex-start"}}>
        <View style={styles.container}>
            <Text style={{fontSize:16,fontWeight:"bold",textAlign:"center",padding:7}}>
             {t("accessInfo")}
            </Text> 
            {repetableFields.map((field, index) => (
            <View key={index} style={styles.mb2}>
                <TextInput
                  placeholder="Key"
                  value={field.key}
                  onChangeText={(text) => updateInputField(index, text, field.value)}
                  style={[styles.input,{color:color.textColor}]}
                />
                <TextInput
                  placeholder="Value"
                  value={field.value}
                  onChangeText={(text) => updateInputField(index, field.key, text)}
                  style={[styles.input,{color:color.textColor}]}
                />
                <CloseButton onPress={() => removeSelectedInput(index)}/>
            </View>
            ))}
            <View style={{flex:1, flexDirection:"row", justifyContent:"space-around",margin:5}}>
                <RepeatableInputButton icon="add-circle" onPress={addInputField}/>
                <RepeatableInputButton icon="remove-circle" onPress={removeInputField}/>
            </View>
        </View>
    </View>
  
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical:5,
    marginHorizontal:width>=768 ? 50 : 5,
    borderWidth:0.8,
    borderRadius:20,
    paddingVertical:10
},
  input: {
    borderRadius:10,
    borderWidth:0.6,
    ...globalStyles(width).input,
    margin:2, 
  },
  mb2: {
    flex:1,
    flexDirection:width>=500 ? "row" : "column",
    alignItems: 'center', 
    marginHorizontal:10, 
    padding:0,
  }
});

export default RepeatableInput;
