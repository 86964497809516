import { Picker } from "@react-native-picker/picker"
import { useState,useContext, useEffect } from "react";
import { Text, StyleSheet, View,Dimensions, Modal,TouchableOpacity } from "react-native";
import { ThemeContext } from "../../../contexts/ThemeContext";
import globalStyles from "../../../styles/GlobalStyles"

const width = Dimensions.get('window').width;
const generalStyles = globalStyles(width)
export const DatePickerComponent = ({kind,pickerStyle,pickerValue,setPickerValue,label,data}) => {
    const color = useContext(ThemeContext)  
    const [modalVisible, setModalVisible] = useState(false);
    const openModal = () => {
        setModalVisible(true);
      };
      
      const closeModal = () => {
        setModalVisible(false);
      };
    return(
        <View style={{backgroundColor:color.whiteColor}}>
          <Text style={[generalStyles.inputLabel,{fontSize: width > 500 ? 18 : 16}]}>{label}</Text>
          <TouchableOpacity style={pickerStyle} onPress={openModal}>
              <Text style={generalStyles.labeledPickerText}>
                {pickerValue}
              </Text>
          </TouchableOpacity>
          <Modal
            visible={modalVisible}
            animationType="fade"
            transparent={true}
            onRequestClose={closeModal}
            >
              <View style={[generalStyles.modalContainer,{justifyContent:"center",alignItems:"center"}]}>
                  <View style={{ margin:40,padding: 40,borderRadius:40,
                      backgroundColor: color.whiteColor,width:width/4,height:width/4}}>
                      <Picker
                        style={{paddingHorizontal:10,height:40, fontSize:20}}
                        selectedValue={pickerValue}
                        onValueChange={setPickerValue}
                          >
                          {data.map((item,itemIndex) => (
                            <Picker.Item 
                              key={itemIndex} 
                              label={item} 
                              value={kind==="month" ? itemIndex+1 :item} 
                              />
                          ))
                        }
                      </Picker>
                      <View style={{alignItems:"flex-end",marginTop:25}}>
                        <TouchableOpacity style={[generalStyles.shadowStyle,{borderRadius:30,width:100}]} onPress={closeModal}>
                            <Text style={styles.containerText}>Seç</Text>
                        </TouchableOpacity>
                      </View>
                </View>
              </View>
          </Modal>
    </View>
    )
}

const styles = StyleSheet.create({
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        alignItems: 'center',
        padding: 30,
        borderRadius:20,
        width: width/5,
        height: width/6,
    },
    containerText: {
      flex:1,
      padding:7,
      fontSize:width>=500 ? 20 :15,
      fontWeight: 'bold', 
      textAlign: "center",
    },
    closeButton: {
      marginTop: 40,
      alignSelf: 'flex-end',
    },
  })