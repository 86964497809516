import { useState,useContext,useEffect } from "react";
import { Text, StyleSheet, View,Dimensions,Modal,TouchableOpacity,FlatList } from "react-native";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import globalStyles from "../../../../styles/GlobalStyles";
import Input from "../../../input/input";
import CloseButton from "../../../buttons/closeButton";
import React from "react";
import SetFlatlist from "./setFlatlist";

const width = Dimensions.get('window').width;
const generalStyles = globalStyles(width)

const ModalPicker = ({label,data,value,setValue,handleChange,kind, setRepetableFields}) => {

    const color = useContext(ThemeContext)  
    const [modalVisible, setModalVisible] = useState(false);
    const [inputValue,setInputValue] = useState("");
    const [buttonForm,setButtonForm] = useState(false)
    const [selectedItem,setSelectedItem] = useState(false)
    const openModal = () => {
        setModalVisible(true);
      };
      
      const handleClose = () => {
        setModalVisible(false);
        setButtonForm(false);
      };
      let labelStyle = {
        ...styles.label,
        textAlign:"center",
      }
      let pickerWidth;
      let pickerHeight
      if(width>=1000) {
        pickerWidth = width*4/13,
        pickerHeight = width/4
      } else if(width>=768) {
        pickerWidth = width/2-20,
        pickerHeight =  width/2-20
      } else {
        pickerWidth = width-40,
        pickerHeight = width-40
      }
      let pickerOutStyle = {
        ...styles.pickerOutView,
        width:pickerWidth,
        height:pickerHeight,
      }
      function handleButtonChange(item) {
        setButtonForm(true)
        setSelectedItem(item)
      }
      // Burada key atama yapılacak RepetableInputa
      function handleSelectDevice(item) {
        // Repetable Part
        const keys = Object.keys(item.accessInfo);
        const repetableObjectArray = keys.map(key => ({ key: key, value: '' }));
        console.log(repetableObjectArray);
        setRepetableFields(repetableObjectArray)
        // Other Part
        setValue(item.deviceType) 
        const selectedItem = data.find(dataItem => dataItem.deviceType === item.deviceType);
        console.log(selectedItem)
        handleChange("deviceType",selectedItem.deviceType) 

      }
      function handleSelectUser(item) {
        setValue(item.email) 
        const selectedItem = data.find(dataItem => dataItem.email === item.email);
        handleChange("user",selectedItem._id) 
      }
      function handleSelect(item) {
        if (kind==="device") {
          handleSelectDevice(item)
        } else if (kind==="user") {
          handleSelectUser(item)
        } else {
          console.log("Seçim türü belirlenemedi.")
        }
        handleClose()
      }
      // let flex,margin,marginRight;
      // if(kind==="user") {
      //   flex = 0.5
      //   marginRight = 15
      // } else {
      //   flex = 1.5
      //   marginRight = 0
      // }
     let viewFlex; 
     if(kind==="user") {
      viewFlex = width>=768 ? 0.49 : 1.2
     } else {
      viewFlex = 1.35
     }
    return(
        <View style={[styles.container,{flex:viewFlex}]}>
            <Text style={labelStyle}>
              {label}
            </Text>
            <TouchableOpacity style={{flex:1}} onPress={openModal}>
                <Text style={[styles.labeledPickerText]}>
                  {value}
                </Text>
            </TouchableOpacity>
            <Modal
              visible={modalVisible}
              animationType="slide"
              transparent={true}
              onRequestClose={handleClose}
              >
                <TouchableOpacity onPress={handleClose} style={[generalStyles.modalContainer,{justifyContent:"center",alignItems:"center"}]}>
                    <TouchableOpacity pointerEvents="none" activeOpacity={1} onPress={() => {}} style={[pickerOutStyle,{backgroundColor:color.whiteColor}]}>
                        <CloseButton onPress={handleClose} kind="setUser"/>
                        <Input
                          label={`${label} Filtrele`}
                          value={inputValue}
                          onChange={(value) => 
                          setInputValue(value)} 
                          />
                        <SetFlatlist 
                          data={data} 
                          kind={kind}
                          selectedItem={selectedItem}
                          inputValue={inputValue} 
                          handleButtonChange={handleButtonChange} 
                          handleSelect={handleSelect}
                          buttonForm={buttonForm}
                          setButtonForm={setButtonForm} 
                          />
                    </TouchableOpacity>
                </TouchableOpacity>
            </Modal>
        </View>
    )
}
const styles = StyleSheet.create({
  pickerOutView: {
    justifyContent:"flex-start",
    alignItems:"center",
    margin:20,
    padding: 20,
    borderRadius:40,
  },
  container: {
    flex:1,
    alignSelf:width>=768 ? "flex-end" : "flex-start",
    flexDirection:"row",
    padding:width>=500 ? 7 : 5,
    borderWidth:0.6,
    borderRadius:10,
    marginBottom:width>=500 ? 5 : 20,
  },
  label: {
    ...globalStyles(width).input,
    justifyContent:"center",
    alignItems:"center",
    paddingVertical:width<500 ? 7 : 0,
    padding:0,
    paddingRight:5,
    marginRight:5,
    borderRightWidth:1,
  },
  labeledPickerText:{
    ...globalStyles(width).input,
    padding:0,
    paddingLeft:5,
  },
})
  export default ModalPicker