import Cookies from "js-cookie";
import constants from "../../resources/constants";
async function getDeviceById(data) {
    const response = await fetch(`${constants.API_URL_USER}/get-device-by-id`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData;
}

const getDeviceDataByDeviceId = async (data) => {
    const clientToken = Cookies.get('clientToken');
    const dataRaw = {
        token: clientToken,
        deviceId: data._id,
    };

    try {
        console.log("ahmet:",dataRaw);
        const data = await getDeviceById(dataRaw);
        console.log("mehmet.",data);
        return data.result;
    } catch (error) {
        console.log('error', error);
    }
};
export default getDeviceDataByDeviceId;