import React, { useState, useEffect, useContext, } from "react";
import { View, Text,TouchableOpacity, StyleSheet,Dimensions,ScrollView } from 'react-native';
import { ThemeContext } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { Fontisto, MaterialIcons,FontAwesome,FontAwesome5, AntDesign, MaterialCommunityIcons} from '@expo/vector-icons';
import globalStyles from "../../styles/GlobalStyles";
import { GlobalFunctionsContext, GlobalStateContext } from "../../contexts/GlobalStatesContext";
import {linking} from "./../../screens/StackScreens/linksURL"
import DrawerButton from "../buttons/drawerButton";
import { IconButton } from "../buttons/iconButton";
import ExitButton from "../buttons/exitButton";
import { useNavigation,useRoute } from "@react-navigation/native";
import logout from "../../API/auths/logout"

const width = Dimensions.get('window').width;
const DrawerContent = ({kind}) => {
  const {t} = useTranslation()
  // Dont delete this
  const{isDrawerSmall, setGlobalStates} = useContext(GlobalStateContext)
  const updateGlobalState = (key, value) => {
    setGlobalStates(prevState => ({ ...prevState, [key]: value }));
  }
  function changeContainerSize() {
    updateGlobalState("isDrawerSmall",!isDrawerSmall)
  }
  const navigation = useNavigation()
  const color = useContext(ThemeContext)
  const { removeAPIToken } = useContext(GlobalFunctionsContext);
  const logOutHandler = async() => {
    logout(removeAPIToken)
  }
  const generalScreens = linking.config.screens;
  const navigateFunction = (route) => {
    navigation.navigate(route)
  }

  let drawerBoxStyle;
  if(width>=500) {
    drawerBoxStyle = {
      ...styles.drawerBox,
      width:isDrawerSmall ? 60 : 250,
      height: 500
    }
  } else {
    drawerBoxStyle = {
      zIndex: 1,
      height:500,
      position:"fixed",
      ...globalStyles(width).shadowRadiusStyle,
      borderRadius:25,
      paddingVertical:5,
      paddingHorizontal:0,
      top:130,
      margin:5,
      width:isDrawerSmall ? 40 : 180
      }
  }
    return (
      <View style={[drawerBoxStyle,{backgroundColor:color.whiteColor,margin:kind==="tab" ? 12 : 10}]}>
          <TouchableOpacity 
            style={{alignItems:"flex-end",padding:7,marginTop:10}} 
            onPress={changeContainerSize}
            >
              <Fontisto name="nav-icon-list" size={24} color="black" />
          </TouchableOpacity>
          <ScrollView style={{marginVertical:10,paddingVertical:10}}>
            {isDrawerSmall ? 
                ( 
                <View>
                    <IconButton onPress={() => navigateFunction("home")} 
                      Icon={<FontAwesome name="home" size={30} color="black"/>}/>
                    <IconButton onPress={() => navigateFunction("users")}
                      Icon={ <FontAwesome5 name="user-alt" size={28} color="black"/>}/>
                    <IconButton onPress={() => navigateFunction("entities")} 
                      Icon={ <FontAwesome5 name="users" size={25} color="black"/>}/>
                    <IconButton 
                      onPress={() => navigateFunction("devices")} 
                      Icon={ <MaterialCommunityIcons name="eight-track" size={30} color="black" />}
                      />
                    <IconButton 
                      onPress={() => navigateFunction("all-user-permissions")} 
                      Icon={ <FontAwesome5 name="user-cog" size={25} color="black"/>}
                      />
                </View>
                ):
                ( 
                <View>
                    <View style={{flexDirection:"row",justifyContent:"center",height:55}}>
                        <DrawerButton title={t("returnHome")} onPress={() =>  navigation.navigate("home")}/>
                    </View>
                    <View style={[styles.drawerHeader,{backgroundColor:color.greyColor}]}>
                        <Text>{t("welcome") + " "+ t("firstname") +" "+ t("lastname")}</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent:"flex-end" }}>
                    {Object.keys(generalScreens)
                    .filter(screen => !screen.includes("Single") && !screen.includes("Dash"))
                    .map((screenName,index) => {
                    const { route, header } = generalScreens[screenName];
                    return ( 
                        <DrawerButton 
                          className="drawer"
                          key={index}
                          title={t(header)}
                          onPress={() => navigateFunction(route)}
                          route={route}
                        />
                      )
                    })}
                    <View style={styles.exitView}>
                        <ExitButton className="exit" title={t("exit")} onPress={logOutHandler}/>
                    </View>
                    </View>
                </View>
                )
            }
          </ScrollView>
      </View>
    );
  }

  const styles = StyleSheet.create({
    drawerBox:{
      ...globalStyles(width).shadowDrawerStyle,
      padding:10,
      },
    drawerHeader: {
      height: 60,
      margin: 10,
      paddingVertical:10,
      paddingHorizontal:15,
      borderRadius: 15,
    },
    exitView:{
      alignItems:"flex-end", 
      marginTop:5,
      marginBottom:8, 
      marginRight:10
    }
  })

  export default DrawerContent;