import React,{useContext, useState} from "react";
import { TouchableOpacity, Text, View,Dimensions, Alert } from "react-native";
import {Ionicons} from '@expo/vector-icons'
import { ThemeContext } from "../../contexts/ThemeContext";
import globalStyles from "../../styles/GlobalStyles";
// Close Buttons 

const width = Dimensions.get('window').width;
const DeleteButton = () => {
  const color = useContext(ThemeContext)

  let buttonStyle;
    buttonStyle = {
      width:"auto",
      marginVertical:11,
      justifyContent:"center",
      alignItems: "center",
    }
  return (
              <View style={[globalStyles(width).cellInputWidth,{alignItems:"center"}]}>
                  <View style={{ flexDirection: 'row', alignItems: 'center',justifyContent:"center" }}>
                    <Ionicons name='md-close-circle' size={40} color={color.redbtnColor}/>
                  </View>
              </View>
       )
};


export default DeleteButton;