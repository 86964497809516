import Cookies from "js-cookie";
import constants from "../../resources/constants";
async function createUser(data) {
    const response = await fetch(`${constants.API_URL_USER}/add-user`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    console.log(responseData)
    return responseData;
}
const createUserData = async (informations, setIsLoadingCreate) => {
    setIsLoadingCreate(true);
    const clientToken = Cookies.get('clientToken');
    const createData = {
        ...informations,
        createdAt: {
            $date: {
                $numberLong: "1722958200000"
            }
        },
        updatedAt: {
            $date: {
                $numberLong: "1722958200000"
            }
        }
    };
    const dataRaw = {
        token: clientToken,
        newUser: createData,
    }
    try {
        console.log(dataRaw);
        // await createUser(dataRaw);
    } catch (error) {
        console.log('error', error);
    } 
};
export default createUserData;