import React, {useEffect, useState } from "react";
import { View, Text,Dimensions, ScrollView,FlatList,StyleSheet, Alert } from "react-native";
import Input from "../../components/input/input"
import RepeatableInput from "../../components/repeatableInput/repeatableInput"
import AddFilterButton from "../../components/buttons/addFilterButton";
import globalStyles from "../../styles/GlobalStyles";
import ModalPicker from "../../components/pickers/labeledPicker/modalPicker/modalPicker";
import { useTranslation } from "react-i18next";

const width = Dimensions.get("window").width
const DeviceFields = ({informations, handleChange, handleIntCondition, data, pickerData, createDataFunction,
    repetableFields, setRepetableFields}) => {
    const {t} = useTranslation()
    const initialPickerData = t("chooseDeviceType")
    const [pickerValue,setPickerValue] = useState(initialPickerData)
    const generalStyles = globalStyles(width)
    const numColumns = (width) => {
        if (width >= 500) return 2; // Desktop, Tablet
        return 1;                   // Mobile
    };
    const renderItem = ({item,index}) => {
        console.log("zaz:",informations[item.database])
        return ( 
        <View key={index} style={generalStyles.fieldInput}>
            <Input
                backgroundColor="whiteColor"
                value={informations[item.database]}
                label={item.label}
                keyboardType="numeric"
                onChange={(value) => handleIntCondition(item.database, value,handleChange)}
                />
        </View>
        )
    }
    useEffect(() => {
        return () => setPickerValue(initialPickerData)
    },[])

    return (
        <View style={{flex:1}}>
            <View style={generalStyles.fieldBorder}>
                <View style={{flex:1}}>
                        <View style={[generalStyles.addContainer,{flex:"none",flexWrap:"wrap"}]}>
                            <FlatList
                                data={data}
                                style={{flex:1}}
                                keyExtractor={(item) => item}
                                renderItem={renderItem}
                                numColumns={numColumns(width)}
                                />
                            <View style={{flex:1,flexDirection:"row"}}>
                            <ModalPicker
                                setRepetableFields={setRepetableFields} 
                                label={t("deviceType")} 
                                kind="device"
                                data={pickerData} 
                                value={pickerValue} 
                                setValue ={setPickerValue} 
                                handleChange={handleChange}
                                />
                            </View>
                        </View>
                        <RepeatableInput 
                            repetableFields={repetableFields} 
                            setRepetableFields={setRepetableFields}
                            />
                        <View style={generalStyles.addContainerButton}>
                            <AddFilterButton style="add" title={t("addDevice")} onPress={createDataFunction}/>
                    </View>
                </View>
            </View>
            
        </View>
    );
}

export default DeviceFields

