import Cookies from "js-cookie";
import constants from "../../../resources/constants";
async function deleteUserPermission(data) {
    const response = await fetch(`${constants.API_URL_USER}/delete-user-permission`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    console.log(responseData)
    return responseData;
}

const deleteUserPermissionData = async (item) => {
    const clientToken = Cookies.get('clientToken');
    const dataRaw = {
        token: clientToken,
        userPermissionId: item._id,
    };

    try {
        console.log("Before delete call:",dataRaw)
        await deleteUserPermission(dataRaw);
        // console.log("After delete call")
    } catch (error) {
        console.log('error', error);
    }
};
export default deleteUserPermissionData;