import React, { useState, useEffect, useContext } from 'react';
import { View,Text, StyleSheet, Dimensions } from 'react-native';
import { ThemeContext } from '../../contexts/ThemeContext';
import globalStyles from '../../styles/GlobalStyles';


const width = Dimensions.get('window').width;
const ClockIndicator = () => {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString())
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <View style={styles.container}>
        <Text style={globalStyles(width).input}>{currentTime}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop:20,
    marginBottom:10,
    padding: 10,
    marginHorizontal:width>=500 ? 10 : 0,
    borderRadius: 15,
    borderWidth: 1,
    alignSelf:"flex-start",
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: 15,
    borderWidth: 5,
    marginLeft:10,
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
  },
});

export default ClockIndicator;