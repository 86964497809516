import { View, Text, Dimensions, Alert,StyleSheet} from 'react-native'
import React,{useState,useContext, useEffect} from 'react'
import ObjectFlatlist from '../../components/flatlists/ObjectFlatlist';
import Wrapper from '../../components/wrapper/wrapper';
import {getAllValues} from "../../utilities/objectMethods/objToArray/getAllValues"
import {getAllKeys} from "../../utilities/objectMethods/objToArray/getAllKeys"
import { getSysCheckData } from '../../API/Device/get-meter-header';
import globalStyles from '../../styles/GlobalStyles';
import { ThemeContext } from '../../contexts/ThemeContext';
import { ReadingDataButton } from '../../components/buttons/readingDataButton';
import { turnDeviceReaderData } from '../../API/Device/turn-device-reader';
import getDeviceDataByDeviceId from '../../API/Device/get-device-by-id';

const width = Dimensions.get("window").width
export function SingleDeviceScreen ({data}) {
  const [deviceData, setData] = useState(data);
  const color = useContext(ThemeContext)
  const keys = getAllKeys(deviceData)
  const values = getAllValues(deviceData)
  const[behavior,setBehavior] = useState(deviceData.isReading)
  const handleHeader = async() => {
    const accessInfo = deviceData.accessInfo
    const sysData = await getSysCheckData({accessInfo})
   console.log(sysData)
  }
  const handleDeviceReader = async () => {
    try {
      console.log("behavior:",behavior)
      const readerData = await turnDeviceReaderData(deviceData._id, behavior);
      if(readerData) {
        const resultData =  await getDeviceDataByDeviceId(deviceData)
        console.log("result:",resultData)
        setData(resultData)
      }
    } catch (error) {
      console.error('Error handling deviceReaderData:', error);
    } 
  };
  useEffect(() => {
    setBehavior(!behavior)
  },[deviceData])
  
  return (
    <Wrapper kind="noDrawer">
      <View style={{marginLeft:40}}>
        <View style={[styles.container,{backgroundColor:color.whiteColor}]}>
          <ObjectFlatlist style="singleDevice" data={keys} />
          <ObjectFlatlist style="singleDevice" data={values} />
        </View>
        <View style={{flex:1,flexDirection:"row",justifyContent:"space-between",marginTop:15,padding:10}}>
          <ReadingDataButton startRead="sysCheck" endRead="sysCheck" onPress={handleHeader}/>
          <ReadingDataButton behavior={behavior} startRead="Okumayı Başlat" endRead="Okumayı Durdur" onPress={handleDeviceReader}/>
        </View>
      </View>
    </Wrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    ...globalStyles(width).shadowRadiusStyle,
    marginTop:10,
    flexDirection:"row",
    padding:6,
    borderRadius:20, 
    alignSelf:"center",
    width:width>=500 ? width/2 : width-80
  }
})