import { View,Alert  } from 'react-native'
import React,{useState,useEffect} from 'react'
import FullScreenLoading from '../../components/loading/FullScreenLoading'
import UserPermFields from './userPermFields'
import listUserPermissionLevels from '../../API/User/list-users'
import createUserPermissionData from '../../API/permissions/userPermissions/add-user-permission'
import { useTranslation } from 'react-i18next'

export const UserPermOperations = ({inputFields,setModalVisible,handleListTableData}) => {
    const [informations, setInformations] = useState("")
    const [isLoadingInitial,setLoadingInitial] = useState(true)
    const [isLoadingCreate,setIsLoadingCreate] = useState(false)
    const[pickerData,setPickerData] = useState([])
    const {t} = useTranslation()
    // Get Input Data
    const initialInformations = Object.fromEntries(inputFields.map(item => [item,""]))

    async function getUsers() {
        const data = await listUserPermissionLevels()
        setPickerData(data)
    }
    const handleChange = (key, value) => {
      setInformations((prevValue) => {
      return {
      ...prevValue,
      [key]: value,
      };
      });
    }; 
    
    async function handleInfo() {
        setInformations(initialInformations)
        await getUsers()
        setLoadingInitial(false)
    }
    // Mount Starts
    useEffect(() => {
        handleInfo()
    },[])

  async function handleCreateTableData(informations) {
    if(informations.user) {
      try {
        await createUserPermissionData(informations, setIsLoadingCreate)
        await handleListTableData();
      } catch (error) {
        console.error('Error handling table data:', error);
      } finally {
        setIsLoadingCreate(false)
        closeModal()
      }
    } 
    else {
        Alert.alert(t("chooseUser"))
        window.alert(t("chooseUser"))
    }
  
  }
  function closeModal() {
    setModalVisible(false)
  }
  let labels;
  // labels = ["Kullanıcım","Modülüm","Organizasyonum","Cihazım","Aksiyon"]
  const combinedArray = inputFields.filter(field => field!=="user").map((field,index) => ({database:field,label:labels ? labels[index] : field}))
    return(
        <View style={{flex:1}}>
          {( isLoadingInitial || isLoadingCreate) ? 
          ( <FullScreenLoading message="Veri yükleniyor, lütfen bekleyin..."/>) :
          (
            <UserPermFields informations={informations} handleChange={handleChange} 
              data={combinedArray} createDataFunction={handleCreateTableData} 
              pickerData={pickerData}
              />  
          )
        }
        </View>
    )
}