import { View, Text, Dimensions,StyleSheet } from 'react-native'
import React,{useState,useEffect, useContext, useCallback} from 'react'
import TableComponent from "../../../components/table/index";
import AddModal from '../../../components/modals/addModal';
import globalStyles from '../../../styles/GlobalStyles'
import JSONData from '../../../data/tableFormat.json';
import listUserPermissionData from '../../../API/permissions/userPermissions/get-user-permissions'
import deleteUserPermissionData from '../../../API/permissions/userPermissions/delete-user-permission'
import { ThemeContext } from '../../../contexts/ThemeContext';
import FullScreenLoading from '../../../components/loading/FullScreenLoading';
import formatUserPermission from './formatUserPermission';
import { GeneralContext } from '../../../contexts/context';
import Wrapper from '../../../components/wrapper/wrapper';
import { UserPermOperations } from '../../../forms/addUserPerms/userPermOperations';
import { useFocusEffect } from '@react-navigation/native';
import { clearContext } from '../../../utilities/clearContext';
import createUserPermDynamicModel from '../../../API/permissions/userPermissions/dynamic-model-userperm';
import AddFilterButton from '../../../components/buttons/addFilterButton';
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../../../contexts/GlobalStatesContext';

const width = Dimensions.get('window').width;
export function UserPermissionScreen({route}) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoadingTable,setIsLoadingTable] = useState(false)
  const[inputFields,setInputFields] = useState([])
  const[tableData,setTableData] = useState(JSONData)
  const{t} = useTranslation()
  const color = useContext(ThemeContext)
  const{isDrawerSmall} = useContext(GlobalStateContext)
  const { 
    data,
    setData
  } = useContext(GeneralContext)
  async function getInputFields () {
    try{
      const inputFields = await createUserPermDynamicModel()
      const limitedFields = inputFields.filter(inputField => inputField!=="updatedAt" && inputField!=="createdAt")
      setInputFields(limitedFields)
    } catch(e) {
      console.error
    }
  }
  async function handleListTableData() {
    try {
      const data = await listUserPermissionData();
        setData((prev) => ({
          ...prev,
          currentData: data,
          filteredData:data,
        }));
        formatData(data)
        getInputFields();
    } catch (error) {
      console.error('Error handling table data:', error);
    } finally {
      setIsLoadingTable(false)
    }
  }

  async function handleDeleteTableData(item) {
    try {
      await deleteUserPermissionData(item)
      await handleListTableData();
    } catch (error) {
      console.error('Error handling table data:', error);
    } 
  }

  async function formatData (data) {
    const formatData = await formatUserPermission(
      data,
      handleDeleteTableData)
      setTableData(formatData)
  }
  useEffect(() => {
    setIsLoadingTable(true);
    handleListTableData()
},[])

  return (
    <>{isLoadingTable ?  
      (<FullScreenLoading message="Tablo Yükleniyor, lütfen bekleyin..."/> )
      : 
      (
        
      <Wrapper kind="noDrawer">
          <AddModal isModalVisible={isModalVisible} setModalVisible={setModalVisible}>
              <UserPermOperations 
                inputFields={inputFields} 
                isModalVisible={isModalVisible} 
                setModalVisible={setModalVisible}
                handleListTableData={handleListTableData}
                />
          </AddModal>
          <View style={globalStyles(width).tableWrapper}>
              <View style={{flex:1}}>
                  <AddFilterButton style="add" title={t("addPermission")} onPress={() => setModalVisible(true)}/>
                  <View style={globalStyles(width).pageTableBorder}>
                      <TableComponent data={tableData} colCount={7} isDrawerSmall={isDrawerSmall}/>
                  </View>
              </View>
          </View>
      </Wrapper>
      )
    }
    </>
  )
}