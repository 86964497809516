import { View, Alert } from 'react-native'
import React,{ useState,useEffect } from 'react'
import DeviceFields from './deviceFields'
import FullScreenLoading from '../../components/loading/FullScreenLoading'
import createDeviceData from '../../API/Device/add-device'
import listDevicePermissionLevels from "../../API/Device/list-devices";
import { handleIntCondition } from '../../utilities/dataType/handleIntCondition'
import { useTranslation } from 'react-i18next'

export const DeviceOperations = ({inputFields,setModalVisible,handleListTableData}) => {
    const [informations, setInformations] = useState("")
    const {t} = useTranslation()
    const [isLoadingInitial,setLoadingInitial] = useState(true)
    const [isLoadingCreate,setIsLoadingCreate] = useState(false)
    const [repetableFields, setRepetableFields] = useState([]);
    const[pickerData,setPickerData] = useState([])
    // Slicing data
    // Get Input Data
    const initialInformations = Object.fromEntries(inputFields.map(item => [item,""]))

    async function getDevices() {
      const data = await listDevicePermissionLevels()
      console.log(data)
      setPickerData(data)
    }
  async function handleInfo() {
    setInformations(initialInformations)
    await getDevices()
    setLoadingInitial(false)
  }
  const handleChange = (item, value) => {
    // Sadece sayılar için kontrol yap
    // console.log(item,value)
      setInformations((prevState) => ({
        ...prevState,
        [item]: value,
      }));
  };

    useEffect(() => {
        handleInfo()
    },[])
    
    let labels;
    // labels = ["Cihaz Adı Custom"]
    // Database - label yapısının amacı customLabel yapısı
    const combinedArray = inputFields
      .filter(field => field !=="accessInfo" && field!=="deviceType" && field !=="isReading")
      .map((field,index) => ({database:field,label:labels ? labels[index] : field}))

    const handleSubmit = () => {
      return new Promise((resolve, reject) => {
        try {
          const transformedObject = {};
          // Each InputPair transforming key:value pair
          repetableFields.forEach(obj => {
            if (obj.key !== "" && obj.value !== "") {
              transformedObject[obj.key] = obj.value;
            }
          });
          resolve(transformedObject);
        } catch (error) {
          reject(error);
        }
      });
    };
    
    async function handleCreateTableData(informations) {
      try {
        const transformedObject = await handleSubmit();
        const updatedInformations = { ...informations, accessInfo: transformedObject, isReading:false};
        await createDeviceData(updatedInformations, setIsLoadingCreate);
        await handleListTableData();
      } catch (error) {
        console.error('Error handling table data:', error);
      } finally {
        setIsLoadingCreate(false);
        setModalVisible(false);
      }
    }
    
    async function createAllDeviceData(informations) {
      if(informations.deviceType) {
        await handleCreateTableData(informations);
      } else {
        Alert.alert(t("chooseDeviceType"))
        window.alert(t("chooseDeviceType"))
      }
     
    }

    return(
        <View style={{flex:1}}>
              { ( isLoadingInitial || isLoadingCreate) ? ( <FullScreenLoading message="Veri yükleniyor, lütfen bekleyin..."/>) :(
                <DeviceFields 
                  informations={informations} 
                  handleChange={handleChange} 
                  handleIntCondition={handleIntCondition}
                  data={combinedArray} 
                  pickerData={pickerData}
                  repetableFields={repetableFields} 
                  setRepetableFields={setRepetableFields}
                  createDataFunction={() => createAllDeviceData(informations)} 
                  />  
              )
            }
        </View>
    )
}