import React, { } from "react";
import { View, Dimensions, } from "react-native";
import FilterComponent from "../../components/filterComponent/FilterComponent";
import AddFilterButton from "../../components/buttons/addFilterButton";
import { Space } from "../../components/space/space";

const width = Dimensions.get("window").width
const DeviceFilterFields = ({inputData,openModal,handleInputChange,data,setData,clearFilter}) => {

    return (
        <View style={{flex:1,flexDirection:"row",justifyContent:"space-between"}}>
            <FilterComponent 
                config={inputData}
                handleInputChange={handleInputChange}
                data={data}
                setData={setData}
                clearFilter={clearFilter}/>
            <AddFilterButton style="add" title="Cihaz Ekle" onPress={openModal}/>
        </View>
    );
}

export default DeviceFilterFields