import { useEffect, useState,useContext } from "react";
import { TouchableOpacity,View, Text, StyleSheet,Dimensions,ActivityIndicator } from "react-native";
import globalStyles from "../../styles/GlobalStyles";
import { ThemeContext } from "../../contexts/ThemeContext";
import { buttonStyler, buttonTextStyler } from "../../utilities/styleMethods/buttonStyleFunctions";
const width = Dimensions.get("window").width
export const ReadingDataButton = ({startRead,endRead,onPress,behavior}) => {
    const[readText,setText] = useState(false)
    const [buttonPress,setPress] = useState(false)
    const[buttonStyle,setStyle] = useState(false)
    const color = useContext(ThemeContext)
    const Loading = (
      <ActivityIndicator size={"small"} color={color.whiteColor} />
    )
    async function generalAPIFunction () {
      setText(Loading)
      try{
        await onPress()
      }catch(error) {
        console.error("getDeviceDate error",error)
      } finally{
        setText(startRead)
      }
    }
    const handleReadData =  async () => {
      await generalAPIFunction()
    };

    const  handleReadStop = async () => {
      await generalAPIFunction()
    }
useEffect(() => {
  setText(behavior ? startRead : endRead)
},[behavior]) 
    let widthButton; 
    if(width<= 500) {
      widthButton = 100;
    } else if(width <= 768) {
      widthButton = 120;
    } else if(width <= 1000) {
      widthButton = 120;
    } else {
      widthButton = 150;
    }
    const buttonBlueStyle = buttonStyler({
        ...styles.button,
        padding:5,
      },color.darkBlueColor,widthButton,40)
      const buttonRedStyle = buttonStyler(
        styles.button
        ,color.redbtnColor,
        widthButton,
        40
      )

      const buttonTextStyle = buttonTextStyler(
        styles.buttonText,
        color.whiteColor,
        width<500 ? 12 : 14
      )

      useEffect(() => {
        if(startRead===endRead) {
          setPress(() => handleReadData)
          setStyle(buttonBlueStyle)
        } else {
          if(readText===startRead) {
            setPress(() => handleReadData)
            setStyle(buttonBlueStyle)
          } else if(readText===endRead) {
            setPress (() =>handleReadStop)
            setStyle(buttonRedStyle)
          } else {
            setPress(() => console.log("bekle"))
          }
        }
      },[readText])
    return (
        <TouchableOpacity
          activeOpacity={0.8}
          style={buttonStyle}
          onPress={buttonPress}
          >
            <Text style={buttonTextStyle}>
              {readText}
            </Text>
      </TouchableOpacity>
    );
  }
  const styles = StyleSheet.create({
    button: {
      ...globalStyles(width).shadowRadiusStyle,
      padding:5,
      textAlign: "center",
      alignItems:"center",
      justifyContent:"center",
      fontSize: 12,
      alignSelf:"flex-end",
    },
    buttonText: {
      textAlign: "center",
    },
  });