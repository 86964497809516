import React,{ useContext, useState } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { Text, StyleSheet, View, TextInput,Dimensions } from "react-native";
import { useTranslation } from "react-i18next";
import globalStyles from "../../styles/GlobalStyles";

const width = Dimensions.get('window').width;
const generalStyles = globalStyles(width)
// General Inputs except for input 2-3
const Input = (props) => {
  const color = useContext(ThemeContext)
  const {t} = useTranslation()
  const handleClick = (value) => {
      props.onChange(value);
  };
  let inputDivStyle;
  // Filter 1-3
  if(props.styleType ==="filter") {
      inputDivStyle = {
        width: "85%",
      }
  }// Filter 2 && Others
  else  {
    inputDivStyle = {
      width: "100%",
    }
  }
  const [focused,setFocused] = useState(false)
  
  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };
 // Color Change
  // let backgroundColor
  // if(focused) {
  //   if(props.backgroundColor === "mainColor") {
  //     backgroundColor = color.mainColor
  //   }else if(props.backgroundColor === "blueColor") {
  //     backgroundColor = color.mainColor
  // } else {
  //   backgroundColor = color.whiteColor
  // }}
  // else {
  //   if(props.backgroundColor === "mainColor") {
  //     backgroundColor = color.greenInputColor
  //   }else if(props.backgroundColor === "blueColor") {
  //     backgroundColor = color.darkBlueColor
  // } else {
  //   backgroundColor = color.greyColor
  // }
  // }
  
  return (
    <View style={inputDivStyle}>
      <Text style={[styles.inputLabel,{color: props.kind==="black" ? color.whiteColor :color.textColor}]}>
        {t(props.label)}
      </Text>
      <TextInput
        value={props.value}
        onChangeText={handleClick}
        style={[ styles.inputComponent,props.style,{
          backgroundColor:color.whiteColor,borderColor:color.textColor}
        ]}
        multiline={props.inputType==="long" ? true : false}
        focusable={false}
        placeholder={props.placeholder}
        inputMode={props.keyboardType}
        secureTextEntry={props.keyboardType==="password" ? true : false}
        onFocus={handleFocus}
        onBlur={handleBlur}
        required={true}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  inputLabel: {
    padding:5,
    alignSelf: "start",
    ...generalStyles.inputLabel,
  },
  inputComponent: {
    borderRadius:10,
    borderWidth:0.6,
    ...generalStyles.input,
  },
  
});
export default Input;
