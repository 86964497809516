import { TouchableOpacity,View } from "react-native"
import { Ionicons } from '@expo/vector-icons'
import { useContext } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"

export const RepeatableInputButton = ({icon,onPress}) => {
const color = useContext(ThemeContext)
return (
    <TouchableOpacity onPress={onPress} style={{width:40,height:40}}>
        <View style={{ flexDirection: 'row', alignItems: 'center',justifyContent:"center" }}>
            <Ionicons name={icon} size={45} color="black"/>
        </View>
    </TouchableOpacity>
)
}

