
import DeleteButton from "../../../components/buttons/deleteButton";
import EditableCell from "../../../components/cellComponents/editableCell";
import NavigationCell from "../../../components/cellComponents/navigationCell";
import {inputDataWithButton} from "../../../utilities/formatMethods/insideFormats/inputDataWithButton"
import { objectTransformer } from "../../../utilities/formatMethods/insideFormats/objectTransformer";
async function formatUserLevel(inputData,updateFunction,deleteFunction) {
    // Initialize the output object
    const data = await inputData
    const outputData =  await inputDataWithButton(data)
    inputData.forEach((item, index) => {
      for (let key in outputData.data) {
        let transformedItem = objectTransformer(item[key])
        if(key==="_id") {
          let previousCells = outputData.data["_id"]["cells"];
          const navigationCell = (
            <NavigationCell 
              data={item}
              routeName="single-user-tab" 
              screen="user-permissions" 
              style="User"
              />
          ) 
          outputData.data["_id"]["cells"] = [...previousCells,navigationCell];
        } else if( key==="Sil") {
          let previousButtonCells = outputData.data["Sil"]["cells"];
          const closeButton = (
            <DeleteButton 
              item={item}
              onPress={() => deleteFunction(item)} 
              />
          )
          outputData.data["Sil"]["cells"] = [...previousButtonCells,closeButton];
        } else {
          const previousCells = outputData.data[key].cells;
          const editableCell = (
            <EditableCell 
              data={item} 
              item={transformedItem} 
              updateFunction={updateFunction}
              keyName={key} 
              style="User"
              />
          ) 
          outputData.data[key].cells = [...previousCells, editableCell];
        }
      }
    });
    return outputData;
  }
  
  export default formatUserLevel;