import React,{useState,useContext,useEffect } from "react";
import { Text, StyleSheet, Dimensions,View,TouchableOpacity } from "react-native";
import { ThemeContext } from "../../contexts/ThemeContext";
import TimeAsDatePicker from "../pickers/datePicker/timeAsDatePicker"
import Input3 from "../input/input3"
import { useTranslation } from "react-i18next";
const width = Dimensions.get('window').width;
const DatepickerInputModal = ({ clearFilter }) => {
  const color = useContext(ThemeContext);
  const {t} = useTranslation()
  const [pickerLabel,setPickerLabel] = useState("01/01/2000  12:30:30")
  
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);

  const showDatePicker = () => {
    setDatePickerVisible(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisible(false);
  };


  useEffect(() => {
  setPickerLabel("01/01/2000 12:30:30")
  },[clearFilter])
  return (
    <View style={{flex:1}}>
        <TouchableOpacity style={{flex:1}} onPress={showDatePicker} >
            <Input3 style="multiline"
              backgroundColor="mainColor"
              value={pickerLabel}
              />
        </TouchableOpacity>
        <View style={styles.modalContainer}>  
            <TimeAsDatePicker
              setLabel = {setPickerLabel}
              visible={datePickerVisible}
              onCancel={hideDatePicker}
              clearFilter = {clearFilter}
              />
        </View>
    </View>
  );
};

const styles = StyleSheet.create({
  picker: {
    paddingHorizontal:10,
    fontSize:18,
    borderWidth: 1,
    padding:7,
    outlineStyle: "none",
    width:120
  },
});
export default DatepickerInputModal;