import React,{useContext} from "react";
import { TouchableOpacity, Text, StyleSheet,Dimensions } from "react-native";
import { ThemeContext } from "../../contexts/ThemeContext";
import globalStyles from "../../styles/GlobalStyles";
import { buttonStyler, buttonTextStyler } from "../../utilities/styleMethods/buttonStyleFunctions";
const width = Dimensions.get('window').width;
// Screend Add Buttons && Form Buttons && Filter Component Buttons
const ResetButton = ({ title, onPress,style}) => {
  const color = useContext(ThemeContext)
  function handlePress() {
    onPress();
  }
  const buttonStyle = buttonStyler({
    ...styles.button,
    marginBottom:-5,
    ...globalStyles(width).shadowRadiusStyle,
  },color.darkBlueColor,50,30)
  const buttonTextStyle = buttonTextStyler(styles.text,color.whiteColor)
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={buttonStyle}
      onPress={handlePress}
      >
        <Text style={buttonTextStyle}>
          {title}
        </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 10,
    textAlign: "center",
    alignItems:"center",
    justifyContent:"center",
    fontSize: 12,
    alignSelf:"flex-end",
    height:30,
    marginRight:2,
  },
  text: {
    textAlign: "center",
  },
});

export default ResetButton;