import { View } from 'react-native'
import React,{useState,useEffect } from 'react'
import FullScreenLoading from '../../components/loading/FullScreenLoading'
import UserFields from './userFields'
import createUserData from '../../API/User/add-user'
export const UserOperations = ({inputFields,setModalVisible,handleListTableData}) => {
    const [informations, setInformations] = useState("")
    const [isLoadingCreate,setIsLoadingCreate] = useState(false)
    // Get Input Data
    const initialInformations = Object.fromEntries(inputFields.map(item => [item,""]))

    const handleChange = (type, value) => {
      setInformations((prevValue) => {
      return {
      ...prevValue,
      [type]: value,
      };
      });
    }; 
    useEffect(() => {
        setInformations(initialInformations)
    },[])
    let labels;
    // labels = ["Email","Şifre","Gizli Token"]
    const combinedArray = inputFields.map((field,index) => ({database:field,label:labels ? labels[index] : field}))
    
    async function handleCreateTableData(informations) {
      try {
        await createUserData(informations, setIsLoadingCreate)
        await handleListTableData();
      } catch (error) {
        console.error('Error handling table data:', error);
      } finally {
        setIsLoadingCreate(false)
         setModalVisible(false)
      }
    }
    return(
        <View style={{flex:1}}>
          { isLoadingCreate ? 
          ( <FullScreenLoading message="Veri yükleniyor, lütfen bekleyin..."/>) :
          (
            <UserFields 
              informations={informations} 
              handleChange={handleChange} 
              data={combinedArray} 
              createDataFunction={handleCreateTableData} 
              />  
          )
          }
        </View>
    )
}