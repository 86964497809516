import constants from "../../resources/constants";
import Cookies from "js-cookie";
async function turnDeviceReader(data) {
    try {
      const response = await fetch(`${constants.API_URL_USER}/turn-device-data-reader`, {
        method: 'POST',
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error("Hata:", error.message);
      return false;
    }
  }
  
  export const turnDeviceReaderData = async (deviceObjectId,isReading) => {
    const clientToken = Cookies.get('clientToken');
    let behavior;
    if(isReading===true){
       behavior = "on"
    }
    if(isReading===false){
       behavior = "off"
    }
    const dataRaw = {
      "deviceObjectId": deviceObjectId,
      "token": clientToken,
      "behavior": behavior,
    };
    try {
      console.log("dataRaw:",dataRaw)
      const responseData = await turnDeviceReader(dataRaw);
      console.log("ResponseTurnDevice:",responseData)
      return responseData
    } catch (error) {
        console.log('error', error);
    }
  }