import React,{useState, useContext,useEffect} from 'react'
import { View, Text,TouchableOpacity,StyleSheet,Dimensions,FlatList } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import {ThemeContext} from "../contexts/ThemeContext"
import globalStyles from '../styles/GlobalStyles'
import Wrapper from '../components/wrapper/wrapper'

export function DashboardScreen () {
    const width = Dimensions.get('window').width
    const navigation = useNavigation()
    const color = useContext(ThemeContext)
    const [isDrawerSmall, setContainerSize] = useState(false);
    const changeContainerSize = () => {
      setContainerSize(!isDrawerSmall);
    };
  const numColumns = (width) => {
    if (width >= 1000) return 3; // Desktop
    if (width >= 500) return 2;  // Tablet
    return 1;                   // Mobile
  };
  let height;
  if(width >= 1000){
    height = 300
  } else if(width >= 768){
    height = 300
  } else if(width >= 500){
    height = 200
  } else {
    height = 350
  }
  let margin;
  if(width >= 768){
    margin = 10
  } else if(width >= 500){
    margin = 4
  } else {
    margin = 10
  }
  const dashboardButtonsStyle = {
    flex:width>=500 ? 1 : "none",
    justifyContent:"center",
    margin:margin,
    marginLeft:width>=500 ? 10 : 50,
    ...globalStyles(width).shadowRadiusStyle,
    borderRadius:width>=768 ? 50 : 40,
    height:height,
    padding:10,
    backgroundColor:color.mainColor,
  }
  const dashboardData = [
    {routeName:"users",label:"Users"},
    {routeName:"devices",label:"Devices"},
    {routeName:"entities",label:"Entities"},
  ]

  const renderItem = ({item,index}) => (
    <TouchableOpacity
      activeOpacity={0.8}
      style={dashboardButtonsStyle}
      onPress={() => navigation.navigate(item.routeName)}
      >
        <Text style={[styles.buttonTextFilter,{color:color.textColor}]}>
          {item.label}
        </Text>
    </TouchableOpacity>
  )
     
    return (
      <Wrapper isDrawerSmall={isDrawerSmall} changeContainerSize={changeContainerSize}>
          <FlatList
            data={dashboardData}
            keyExtractor={(item) => item.routeName}
            renderItem={renderItem}
            numColumns={numColumns(width)}
            />
      </Wrapper>
    )
}
const styles = StyleSheet.create({
    buttonTextFilter: {
      marginVertical: "auto",
      fontSize:20,
      fontWeight:"bold",
      textAlign: "center",
      padding:10,
    },
  });
  
  