import { View,FlatList,Dimensions,ScrollView } from 'react-native';
import React, { useEffect, useState } from 'react';
import CellComponent from '../table/cellComponent'

const width = Dimensions.get('window').width;
const ObjectFlatlist = ({ data,isDrawerSmall,deleteIndex,setDeleteIndex,
  colCount,listFunction,updateFunction,style }) => {
  const [minusWidth,setWidth] = useState(false)
  const renderItem = ({ item, index }) => (
    <CellComponent
      deleteIndex={deleteIndex}
      setDeleteIndex={setDeleteIndex}
      item={item} 
      rowIndex={index} 
      style={style}
      listFunction={listFunction} 
      updateFunction={updateFunction}
      />
  )
  useEffect(() => {
    const calculatedWidth = isDrawerSmall ? 122 : 312;
    setWidth(calculatedWidth);
  }, [isDrawerSmall]);
  let maxCount;
  if(width>=1800) {
    maxCount = 6
  } else if(width>=1250) {
    maxCount = 5
  } else if(width>=768) {
    maxCount =  3.5
  } else {
    maxCount = 0
  }
  let selectedCount = maxCount > colCount ? colCount :maxCount
  return (
      <FlatList
        style={{width:(width-(minusWidth))/selectedCount,flex:1}}
        contentContainerStyle={{flex:1}}
        data={data}
        renderItem={renderItem}
        />
  )
}

export default ObjectFlatlist;