const globalStyles = (width) => {

  const cellWidthValue = width >= 1800 ? 200 : (width >= 1000 ? 170 : (width >= 300 ? 110 : 100));
  const pickerWidth = width >= 1000 ? width / 4 : (width >= 768 ? width / 2 - 20 : width - 40);
  const pickerHeight = width >= 1000 ? width / 4 : (width >= 768 ? width / 2 - 20 : width - 40);
  
  const centeredColFlex = {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
  }
  const smallWidth = width > 500 ? 15 : 12
  const leftedSmallText = {
    textAlign: "left",
    fontSize: smallWidth,
  }
  const centeredSmallText = {
      textAlign: "center",
      fontSize: smallWidth,
  }
  
  const midWidth =  width > 500 ? 20 : 15
  const leftedMidText = {
    textAlign: "left",
    fontSize: midWidth,
  }
  const centeredMidText = {
      textAlign: "center",
      fontSize: midWidth,
  }
  const centeredRowFlex = {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      margin:7,
  }
  const input = {
      ...leftedSmallText,
      flex:1,
      padding: 7
  }
  const mediumShadowStyle = {
    shadowColor: 'rgba(0, 0, 0, 0.25)',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 1,
      shadowRadius: 3,
      elevation: 3, // Android için shadow
  }
  const generalButton = {
    maxWidth:width>=500 ? 120 : 115,
    height:50,
    borderRadius: 30,
    justifyContent:"center",
  }
  return {
    // GENERAL
    centeredColFlex: {
      ...centeredColFlex,
    },
    centeredRowFlex: {
        ...centeredRowFlex,
    },
    leftedMidText: {
        ...leftedMidText,
    },
    centeredMidText: {
      ...centeredMidText,
    },
    leftedSmallText: {
      ...leftedSmallText,
    },
    centeredSmallText: {
      ...centeredSmallText,
    },
    // BUTTON
    startButton: {
      alignSelf:"flex-start",
      ...generalButton,
    },
    endButton: {
      alignSelf:"flex-end",
      ...generalButton,
    },
    startShadowedButton: {
      alignSelf:"flex-start",
      ...generalButton,
      ...mediumShadowStyle,
    },
    endShadowedButton: {
      alignSelf:"flex-end",
      ...generalButton,
      ...mediumShadowStyle,
    },
    buttonText: {
      ...centeredSmallText,
      padding: width>=500 ? 12 : 10
    },
    // PICKER
    pickerChooseButton: {
      flex:0.25,
      padding:8,
      height:40,
    },
    pickerChooseText: {
      ...centeredMidText,
    },
    // SWITCH
     switchContainer: {
      ...centeredColFlex,
      padding:10,
    },
    switchMessage: {
        textAlign:"center",
        fontSize:width > 500 ? 13 : 11,
        padding:5,
    },
    switchLabel: {
        flex:1,
        ...leftedMidText,
        padding: 7
    },
    // TAB
    tabStyle: {
      marginTop:10,
      marginHorizontal:10,
      height:60,
      padding:7,
      borderRadius:10,
    },
    tabText: {
      ...centeredSmallText,
      padding:5,
    },
    screenWrapper: {
      flex:1,
      flexDirection: width >= 1000 ? 'row' : 'column',
      padding: width >= 500 ? 7 : 0
    }, 
    pageTableBorder:{
      flexDirection:"row",
      marginTop:6,
      marginBottom:13,
    },
    input: {
        ...input,
        outlineStyle: 'none',
    },
    inputLabel: {
      ...input,
    },
    labeledInputView: {
        ...centeredColFlex,
        height:"100%"
    },
    labeledInputLabel: {
        ...input,
        borderWidth:1,
        borderRightWidth:0,
    },
    labeledInputComponent: {
        ...input,
        outlineStyle: 'none',
    },
    labeledInputText:{
      fontSize:width>=500 ? 20 :15,
      paddingRight:width>=500 ? 10 : 5,
      textAlign:"right",
      alignSelf:"center",
      flex:0.06
    },
    labeledPickerText:{
      ...leftedMidText,
      padding:10,
    },
    editableCellButton: {
      flex:1,
      justifyContent: 'center',
      alignSelf:"center",
    }, 
    cellOutView:{
      justifyContent:"center",
    },
    cellView: {
      flex:1,
      width:"auto",
      marginVertical:"auto",
    },
    cellComponent: {
      flex:1,
      fontWeight:"400",
      textAlign: "center",
      padding: 7,
    },
    cellWidth: {
      width:cellWidthValue,
      alignItems:"flex-start"
    },
    cellInputWidth: {
      width:cellWidthValue,
    },
    filterWrapper: {
      width:width>1000 ?  "30%" : "100%"
    },
    filterContainer: {
      flex: 1,
      marginBottom:width>=500 ? 13 : 0,
    },
    filterBorder: {
      marginVertical: 10,
      marginHorizontal:7,
      marginLeft:width<500 ? 52 : 7,
      paddingVertical:15,
      paddingHorizontal:5,
      borderRadius: 20,
    },
    filterInputCloseWrapper: { 
      ...centeredColFlex,
      marginLeft:width>498? 20 :0
    },
    tableWrapper:{
      flex:1, 
      padding:10, 
      flexDirection:"row",
  },
    tableWrapperOther: {
      width:width>1000 ?  "70%" : "100%",
      flexDirection:"column",
      padding:10
    },
    modalTableWrapper: {
      width:"100%",
      marginTop:25,
      flexDirection:"column",
      padding:10,
    },
    homeTableWrapper:{
      flexDirection:"row",
      alignItems: "center",
      padding:7,
    },
    TrackSubsHeader: {
      fontSize:20,
      marginBottom:20,
      fontWeight: "bold",
      textAlign: "left"
    },
    // Only Shadow
    shadowStyle: {
      shadowOffset: {
        width: 0,
        height: 6,
      },
      shadowOpacity: 0.25,
      shadowRadius: 23.1,
      elevation: 6, // Android için shadow özelliği
    },
    // Shadow with Radius
    shadowRadiusStyle: {
      borderRadius:30,
      shadowOffset: {
        width: 0,
        height: 6,
      },
      shadowOpacity: 0.25,
      shadowRadius: 23.1,
      elevation: 6, // Android için shadow özelliği
    },
    shadowDrawerStyle: {
      borderRadius:30,
      shadowColor: 'rgba(0, 0, 0, 0.25)',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 1,
      shadowRadius: 3,
      elevation: 3, //
    },
    modalContainer: {
      flex:1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
      flex:1,
      margin:20,  
      padding: 25,
      borderRadius:40,
    },
    fieldBorder: {
      flex:1,
      flexDirection:"row",
      margin:7,
      paddingTop: width>=768 ? 10 : 20,
      fontSize: 11,
      borderRadius: 20,
    },
    fieldInput: {
      flex:1,
      marginVertical:5,
      marginHorizontal:5, 
    },
    fieldFlatlistView: {
      flex:1,
      flexDirection:"column",
    },
    addContainer: {
      flex:1,
      flexDirection:width>=768 ? "row" : "column",
      paddingHorizontal:width>=768 ? 50 : 5,
      marginBottom:20,
    },
    addContainerButton: {
      marginTop:20,
      alignItems: "flex-end",
      paddingRight:width>=768 ? 50 : 5
    }
  }
}
  export default globalStyles;
  