import { View, StyleSheet,Dimensions } from 'react-native'
import React,{useState, useEffect,useContext, useCallback} from 'react'
import TableComponent from "../../../components/table/index";
import globalStyles from '../../../styles/GlobalStyles';
import listUserPermissionLevels from '../../../API/User/list-users';
import formatUserLevel from './formatUserLevel';
import JSONData from '../../../data/tableFormat.json';
import FullScreenLoading from '../../../components/loading/FullScreenLoading';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { GeneralContext } from '../../../contexts/context';
import Wrapper from '../../../components/wrapper/wrapper';
import AddModal from '../../../components/modals/addModal';
import { UserOperations } from '../../../forms/addUser/userOperations';
import { useFocusEffect } from '@react-navigation/native';
import { clearContext } from '../../../utilities/clearContext';
import updateUserData from '../../../API/User/update-user';
import deleteUserData from '../../../API/User/delete-user';
import createUserDynamicModel from '../../../API/User/dynamic-model-user';
import AddFilterButton from '../../../components/buttons/addFilterButton';
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../../../contexts/GlobalStatesContext';

const width = Dimensions.get('window').width;

export function UserPermissionLevelScreen() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoadingTable,setIsLoadingTable] = useState(false)
  const[inputFields,setInputFields] = useState([])
  const {t} = useTranslation()
  const[tableData,setTableData] = useState(JSONData)
  const { 
    data,
    setData
  } = useContext(GeneralContext)
  const{isDrawerSmall} = useContext(GlobalStateContext)

  const color = useContext(ThemeContext)
  async function getInputFields () {
    try{
      const inputFields = await createUserDynamicModel()
      const limitedFields = inputFields.filter(inputField => inputField!=="updatedAt" && inputField!=="createdAt")
      setInputFields(limitedFields)
    } catch(e) {
      console.error
    }
  }
  async function handleUserTableData() {
    try {
      const data = await listUserPermissionLevels();
        setData((prev) => ({
          ...prev,
          currentData: data,
          filteredData:data,
        }));
        formatData(data)
        getInputFields();
    } catch (error) {
      console.error('Error handling table data:', error);
    } finally {
      setIsLoadingTable(false)
    }
  }
  async function handleDeleteTableData(item) {
    try {
      await deleteUserData(item)
      await handleUserTableData();
    } catch (error) {
      console.error('Error handling table data:', error);
    }
  }
  async function handleUpdateTableData(data,item,key) {
    try {
      await updateUserData(data,item,key)
      await handleUserTableData();
    } catch (error) {
      console.error('Error handling table data:', error);
    }
  }
  // Handle list ve filterOperationsda debounceSeachde çalıştırılır.
  async function formatData (data) {
    const formatData = await 
      formatUserLevel(
        data, 
        handleUpdateTableData,
        handleDeleteTableData
      )
    setTableData(formatData)
  }
  useFocusEffect(
    useCallback(() => {
      setIsLoadingTable(true);
      handleUserTableData()
      return () => clearContext(setData)
    }, [])
  );
  function openModal() {
    setModalVisible(true)
  }
  return (
    <>{isLoadingTable ? (<FullScreenLoading message="Tablo Yükleniyor, lütfen bekleyin..."/>):
        (
        <Wrapper 
          >
           <AddModal isModalVisible={isModalVisible} setModalVisible={setModalVisible}>
              <UserOperations 
                inputFields={inputFields} 
                setModalVisible={setModalVisible} 
                handleListTableData={handleUserTableData} 
                />
            </AddModal>
            <View style={globalStyles(width).tableWrapper}>
                <View style={{flex:1}}>
                    <AddFilterButton style="add" title={t("addUser")} onPress={() => setModalVisible(true)}/>
                    <View style={globalStyles(width).pageTableBorder}>
                        <TableComponent data={tableData} colCount={5} isDrawerSmall={isDrawerSmall}/>
                    </View>
                </View>
            </View>
        </Wrapper>
        )
    }
    </>
  )
}