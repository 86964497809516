import Cookies from "js-cookie";
import constants from "../../resources/constants";
async function createEntity(data) {
    const response = await fetch(`${constants.API_URL_USER}/create-entity`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData;
}
const createEntityData = async (informations,setIsLoadingCreate) => {
    setIsLoadingCreate(true);
    const clientToken = Cookies.get('clientToken');
    const createData = {
        ...informations,
        createdAt: {
            $date: {
                $numberLong: "1722958200000"
            }
        },
        updatedAt: {
            $date: {
                $numberLong: "1722958200000"
            }
        }
    };
    const dataRaw = {
        token: clientToken,
        newEntity: createData
    };
    console.log(dataRaw);
    try {
        await createEntity(dataRaw);
    } catch (error) {
        console.log('error', error);
    } 
};
export default createEntityData;