import { View, Text, Dimensions } from 'react-native'
import React,{ useState } from 'react'
import Wrapper from '../../components/wrapper/wrapper'
import globalStyles from '../../styles/GlobalStyles';

const width = Dimensions.get("window").width
const UserSettingsScreen = () => {
  return (
    <Wrapper kind="noDrawer">
        <Text style={[globalStyles(width).leftedMidText,{margin:10}]}>UserSettingsScreen</Text>
    </Wrapper>
  )
}
export default UserSettingsScreen;