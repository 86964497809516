import React,{useContext} from "react";
import { TouchableOpacity, Text, StyleSheet,Dimensions } from "react-native";
import { ThemeContext } from "../../contexts/ThemeContext";
import globalStyles from "../../styles/GlobalStyles";
import { buttonStyler, buttonTextStyler } from "../../utilities/styleMethods/buttonStyleFunctions";
import { Ionicons } from "@expo/vector-icons";

const width = Dimensions.get('window').width;

const BackTabButton = ({ onPress }) => {
  const color = useContext(ThemeContext)
  function handlePress() {
    onPress();
  }
  let rawStyle;
  if(width>=500) {
    rawStyle = {
      ...styles.button,
      ...globalStyles(width).shadowDrawerStyle,
      alignSelf:"flex-start",
      marginTop:20,
      marginBottom:10,
      marginLeft:20,
    }
  } else {
    rawStyle = {
      ...styles.button,
      ...globalStyles(width).shadowDrawerStyle,
      top: 20,
      left:10,
      zIndex:1,
    }
  }
  const buttonStyle = buttonStyler(rawStyle,color.warningColor,45,45)
  // const buttonTextStyle = buttonTextStyler(globalStyles(width).centeredMidText,color.whiteColor)

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={[buttonStyle]}
      onPress={handlePress}
      >
        <Ionicons name="arrow-back" size={28} color="white" />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 10,
    textAlign: "center",
    alignItems:"center",
    justifyContent:"center",
  },
});

export default BackTabButton;
