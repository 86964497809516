async function getSysCheck(data) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const response = await fetch("https://read.sys.api.ocloud.pro/sys-check", {
      method: 'POST',
      body: JSON.stringify(data),
      headers: myHeaders,
      redirect: "manual",
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Hata:", error.message);
    return false;
  }
}


export const getSysCheckData = async ({accessInfo}) => {
  const dataRaw = {
    "ipAddress": accessInfo.ipAddress,
    "unitId": accessInfo.unitId,
    "port": accessInfo.port
  };
  try {
    const responseData = await getSysCheck(dataRaw);
    return responseData
  } catch (error) {
      console.log('error', error);
  } 
}