import React, { useContext, useState,useEffect } from 'react';
import { StyleSheet, Text, TouchableOpacity, TouchableHighlight, ScrollView, View, Image, Dimensions } from 'react-native';
import Input from  '../components/input/input'
import LoginButton from '../components/buttons/loginButton';
import { GlobalFunctionsContext, GlobalStateContext } from '../contexts/GlobalStatesContext';
import { ThemeContext } from "../contexts/ThemeContext";
import login from '../API/auths/login';
import { useTranslation } from 'react-i18next';

const width = Dimensions.get('window').width;
export function Login({ navigation }) {
    const color = useContext(ThemeContext)
    const { setGlobalStates } = useContext(GlobalStateContext);
    const { getAPIToken } = useContext(GlobalFunctionsContext);
    const {t} = useTranslation()
    const [informations, setInformations] = useState( {
        email: "kemal.abut@outlook.com",
        password: "bat123"
    })
    const updateGlobalState = (key, value) => {
        setGlobalStates(prevState => ({ ...prevState, [key]: value }));
    }

    // const handleLogin = () => {
    //     getAPIToken();
    // };
    
    const email = "kemal.abut@outlook.com";
    const password = "bat123";

    useEffect(() => {
        handleChange()
    }, [informations.email, informations.password]);

    function handleChange(type, value) {
        setInformations(prevValue => {
          return {
            ...prevValue,
            [type]: value
          }
        })
      }

    //   let formWidth;
    //   if(width>=1000) {
    //     width/2
    //   }if(width>=500) {
    //     width/2
    //   }else {
    //     width
    //   }
      
    return (
        <ScrollView contentContainerStyle={[styles.container,{backgroundColor:color.blackColor}]}>
            <View style={styles.logoContainer}>
                <Image
                    source={require('../assets/logo/logo.gif')}
                    style={[styles.logo,{backgroundColor:color.blackColor}]}
                    resizeMode="contain"
                />      
            </View>
            <View style={[styles.form,{width:width>=500 ? width/4 : "auto"}]}>
                <View style={styles.inputContainer}>
                    <Input 
                        kind="black"
                        value={informations.email} 
                        label={t("email")} 
                        onChange={(value) => handleChange("email", value)} 
                        keyboardType="email-address"
                        />
                </View>
                <View style={styles.inputContainer}>
                    <Input 
                        kind="black"
                        value={informations.password} 
                        label={t("password")}
                        onChange={(value) => handleChange("password", value)} 
                        keyboardType="password"
                        />
                </View>
                <View style={styles.buttonContainer}>
                    <LoginButton 
                        stylesAdd={styles.loginButton} 
                        onPress={() => login(email, password, informations, updateGlobalState, getAPIToken)} 
                        title={t("login")}
                        />
                </View>
                <TouchableOpacity 
                    style={{padding:7}} 
                    onPress={() => navigation.navigate('ForgotPassword')}
                    >
                    <Text style={[styles.forgotPassword,{color:color.whiteColor}]}>
                        {t("forgotPassword")}
                    </Text>
                </TouchableOpacity>
                <TouchableHighlight style={{padding:7}}>
                    <Text style={[styles.help,{color:color.whiteColor}]}>
                        {t("contactCustomer")}
                    </Text>
                </TouchableHighlight>
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    logoContainer: {
        marginTop:0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        height: 200,
        width: 330,
    },
    form: {
        borderRadius: 50,
        padding: 50,
        margin: 15,
        shadowColor: '#fff',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.29,
        shadowRadius: 15,
        elevation: 5,
    },
    inputContainer: {
        marginVertical: 25,

    },
    buttonContainer: {
        marginVertical: 10,
    },
    loginButton: {
        elevation: 8,
        margin: 20,
        backgroundColor: "transparent",
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 12,
        borderWidth: 1,
        shadowColor: '#fff',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.25,
        shadowRadius: 15,
        elevation: 5,
        borderWidth: 0,
    },
    forgotPassword: {
        fontWeight: 'bold',
        marginVertical: 10,
        alignSelf: 'center',
    },
    help: {
        marginVertical: 10,
        alignSelf: 'center',
    },
});
